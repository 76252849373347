@value unit, smBreak from "../../common/metrics.css";
@value colorViolet from "../../common/colors/colors.css";
@value colorPrimary from "../../common/colors/colors.css";
@value colorSecondary from "../../common/colors/colors.css";

.dateInputRange {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: calc( 2 * unit);
    position: relative;
}

.textInput {
    background-color: white !important;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: none;
}

.calendarWrapper {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    z-index: 2;
}

.calendarWrapper.opened {
    display: block;
}

.calendarWrapper.opened + .backdrop {
    display: block;
}

[class="react-datepicker"], [class^="react-datepicker "] {
    font-family: "TT Norms", sans-serif !important;
    padding: 16px;
    box-shadow: 0 6px 12px 0 rgba(41, 43, 50, 0.12);
    border: none !important;
    border-radius: 8px !important;
    display: flex !important;
    justify-content: space-between;
    z-index: 1;
}

.inlinePicker {
    display: flex;
    flex-direction: column;
}

[class*="triangle"] {
    display: none;
}

[class="react-datepicker__header "] {
    padding: 0 !important;
    background-color: white !important;
    border: none !important;
}

[class="react-datepicker__month"] {
    margin: 0;
}

[class*="react-datepicker__week"] {
    display: flex;
    margin-bottom: 4px;
}

[class*="current-month"] {
    font-weight: 700 !important;
    font-size: 16px !important;
    letter-spacing: -0.5px;
    color: colorViolet !important;
    margin-bottom: 20px;
}

[class*="keyboard-selected"] {
    background-color: transparent !important;
    color: colorViolet !important;
}

[class*="react-datepicker__day--"] {
    border-radius: 50% !important;
    border: none;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 28px !important;
    margin: 0 !important;
    height: 28px;
    width: 28px !important;
    background-color: transparent;
    font-weight: 300;
    position: relative;
}

[class*="day--in-range"]:not([class*="day--range-start"]):not([class*="day--range-end"]) {
    background-color: colorSecondary !important;
    color: colorPrimary !important;
    border-radius: 4px !important;
}

[class*="range-start"], [class*="range-end"], [class*="selecting-range-start"] {
    border-radius: 50% !important;
    background-color: colorPrimary !important;
    color: white !important;
    font-weight: 500;
}

[class*="day--in-range"]::before {
    height: 100%;
    content: "";
    display: block;
    position: absolute;
    background-color: colorSecondary;
    z-index: -1;
    top: 0;
}

[class*="day--in-range"]:not([class*="day--range-start"]):not([class*="day--range-end"]):not(:first-child)::before {
    left: -12px;
    width: 20px;
}

[class*="day--range-start"]::before {
    width: 10px;
    right: 0;
}

[class*="day--range-end"]::before {
    width: 20px;
    left: -12px;
}

[class*="day--range-end"]:first-child::before {
    width: 20px;
    left: 0;
}

[class*="react-datepicker__day--"]:not(:first-child) {
    margin-left: 10px !important;
}

[class*="day-names"] {
    margin: 0 !important;
    display: flex;
    justify-content: space-evenly;
}

[class*="day-name"] {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
}

[class*="day-name"]:not(:first-child) {
    margin-left: 10px;
}

[class*="outside-month"] {
    pointer-events: none;
    opacity: 0;
}

[class*="in-selecting-range"] {
    color: colorViolet !important;
    background-color: white !important;
}

[class*="in-selecting-range"]:hover {
    background-color: colorPrimary !important;
    color: white !important;
}

[class*="day--selected"] {
    background-color: colorPrimary !important;
    color: white !important;
}

.calendarWrapper [class*="today"] {
    font-weight: unset !important;
    background-color: white !important;
    color: #ccc !important;
    pointer-events: none;
}

[class*="navigation"] {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 16px !important;
    border: none !important;
    width: 24px !important;
    height: 24px !important;
}

[class*="navigation"]>svg {
    width: 16px;
    height: 16px;
}

[class*="navigation"]>svg:hover>path {
    fill: colorViolet;
}

[class*="navigation--previous"] {
    left: 24px !important;
}

[class*="navigation--next"] {
    right: 24px !important;
}

@media (min-width: smBreak) {
    .inlinePicker {
        width: 608px;
        flex-direction: row;
    }

    .calendarWrapper {
        position: absolute;
        top: calc(100% + 12px);
    }

    .calendarWrapper.opened + .backdrop {
        display: none;
    }
}
