@value unit, smBreak from "../../../../common/metrics.css";

.mapViewContainer {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: calc(100vh - 280px);
  gap: calc(2 * unit);
}

.hotelList {
  overflow: auto;
  flex: 0 0 308px;
  overscroll-behavior: contain;
  position: relative;
}

.hotelList.loading {
  pointer-events: none;
}

.infiniteScroll {
  padding-bottom: 5px;
}

.mapViewContainer button[class*="gm-ui-hover-effect"] {
  display: none !important;
}

.mapViewContainer div[class*="gm-style-iw-d"] {
  padding: 0 !important;
  overflow: auto !important;
  height: auto;
  width: auto;
}

.mapViewContainer div[class*="gm-style-iw"] {
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}

.mapViewContainer div[class*="gm-style"]::after {
  display: none !important;
}

@media (min-width: smBreak) {
  .mapViewContainer {
    flex-direction: row;
    height: calc(100vh - 280px);
    max-height: 900px;
  }
}
