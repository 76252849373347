@value (
  colorViolet,
  colorPolar,
  colorRemy,
  colorSerenade,
  colorAqua,
  colorLightOrange
) from "../../common/colors/colors.css";
@value mdBreak from "../../common/metrics.css";

.notification {
  width: 100%;
  padding: 12px;
  height: auto;
  border-radius: 8px;
  overflow: auto;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 12px rgba(41, 43, 50, 0.24);
  transition: opacity 2s;
}

.notification.information {
  background: colorAqua;
}
.notification.alert {
  background: colorLightOrange;
}
.notification.success {
  background: colorPolar;
}
.notification.warning {
  background: colorSerenade;
}
.notification.error {
  background: colorRemy;
}

.notificationIcon {
  margin-right: 11px;
  flex: 0 0 auto;
}

.notification.information .notificationIcon path {
  fill: #0FA6ED;
}

.notificationText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: colorViolet;
  flex: 1 1 100%;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #717171;
  background: transparent;
}

@media (min-width: mdBreak) {
  .notification {
    width: 488px;
    min-width: 400px;
    max-height: 300px;
    min-height: 100px;
    padding: 26px;
  }
}
