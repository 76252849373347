@value colorShuttleGrey, colorViolet, colorLoblolly, colorMystic, colorSecondary, colorPrimary, colorFoam, colorBackgroundGrey from "../../common/colors/colors.css";
@value unit, mdBreak, lgBreak from "../../common/metrics.css";

.settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settings > *:last-child {
  margin-right: 0;
  flex-grow: 1;
}

.settingsTableContainer {
  position: relative;
}

.nameFilter {
  margin-bottom: 8px;
}

.settingsTable,
.settingsTable thead,
.settingsTable tbody,
.settingsTable th,
.settingsTable td,
.settingsTable tr {
  display: block;
}

.settingsTable {
  width: 100%;
  display: block;
  position: relative;
}

.settingsTable 	.tableHeader tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.settingsTable tr {
  border: 1px solid colorMystic;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 8px;
}

.settingsTable td {
  border: none;
  position: relative;
  padding-left: 35%;
  font-size: 12px;
}

.settingsTable td:not(:last-child) {
  border-bottom: 1px solid colorMystic;
}

.settingsTable td:before {
  position: absolute;
  left: 6px;
  width: 45%;
  padding-right: 10px;
  white-space: nowrap;
  font-weight: 700;
}

.settingsTable .name {
  display: flex;
}

.name,
.status,
.role,
.settingsTable td:last-child {
  padding-top: 8px;
  padding-bottom: 8px;
}

.profilePicture {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 2px solid colorPrimary;
  overflow: hidden;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colorPrimary;
  text-transform: uppercase;
}

.plainText>span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: colorViolet;
}

.role>span, .status>span {
  padding: 6px 16px;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  border-radius: 4px;
  text-transform: uppercase;
  height: 24px;
}

.addButton {
  height: 40px;
  border-radius: 12px;
  padding: 0 16px;
  width: 100%;
  margin-bottom: 16px;
}

.addButtonInner {
  display: flex;
  align-items: center;
  gap: 6px;
}

@media (min-width: mdBreak) {
  .settings {
    flex-direction: row;
  }

  .addButton {
    position: absolute;
    right: 0;
    top: -96px;
    width: auto;
  }

  .settingsTable thead { display: table-header-group },
  .settingsTable tbody { display: table-row-group },
  .settingsTable th { display: table-cell },
  .settingsTable td { display: table-cell },
  .settingsTable tr { display: grid; }

  .settingsTable .tableHeader tr {
    position: static;
    top: unset;
    left: unset;
  }

  .settingsTable tr {
    border: none;
    display: grid;
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    border-bottom: 1px solid colorMystic;
    height: 56px;
    align-items: center;
  }

  .settingsTable td {
    padding-left: 0;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .settingsTable td:not(:last-child) {
    border-bottom: none;
  }

  .settingsTable td:before {
    display: none;
  }

  .name,
  .status,
  .role,
  .settingsTable td:last-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  .settingsTable {
    overflow-y: auto;
    height: 350px;
    scrollbar-gutter: stable;
    padding-right: 4px;
  }

  .settingsTable::-webkit-scrollbar {
    width: 4px;
  }
  
  .settingsTable .tableHeader {
    position: sticky;
    top: 0;
    background-color: colorBackgroundGrey;
    z-index: 1;
  }
  
  .settingsTable .tableHeader th {
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    color: colorShuttleGrey;
    text-align: left;
  }

  .settingsTable td.address {
    color: colorShuttleGrey;
  }

  .profilePicture {
    width: 36px;
    height: 36px;
  }

  .name, .agencyName, .email, .address, .commission {
    padding-right: 8px;
  }

  .commission {
    width: 120px;
  }

  .role, .status {
    width: 163px;
  }
}
