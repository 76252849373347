@value unit from "../../common/metrics.css";
@value colorAmaranth from "../../common/colors/colors.css";

.roomList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 8px 16px;
}

.roomList button {
  width: fit-content;
}

.roomItem {
  width: 100%;
}

.ages {
  margin-bottom: unit;
}

.remove {
  margin-top: calc(-1 * unit);
  margin-bottom: calc(3 * unit);
  color: white;
  background-color: colorAmaranth;
}

.hidden {
  display: none;
}
