@value colorBackgroundGrey from "../../common/colors/colors.css";
@value smBreak from "../../common/metrics.css";

.breadcrumb {
  background-color: colorBackgroundGrey;
}

.hr {
    height: 1px;
    border:0;
    background: #F1F6F9;
}

@media (min-width: smBreak) {
    .hr {
        margin-top: 24px;
    }
}
