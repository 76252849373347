@value colorShuttleGrey, colorViolet, colorCatskillWhite from "../../../common/colors/colors.css";

.itineraryLogs {
    display: flex;
    flex-direction: column;
}

.itineraryLogs h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.75px;
    color: colorViolet;
    margin-bottom: 20px;
}

.row {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;
    padding: 8px 16px;
}

.row:not(.head):nth-child(even) {
    background-color: white;
}

.head {
    font-weight: 700;
    background-color: colorCatskillWhite;
}

.body {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
}
