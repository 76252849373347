@value (
  colorViolet,
  colorShuttleGrey,
  colorAmaranth,
  colorPolar,
  colorCaribbeanGreen,
  colorBackgroundGrey,
  colorRemy,
  colorLightningYellow) from "../../../../../common/colors/colors.css";

@value smBreak from "../../../../../common/metrics.css";

.cancellation {
  padding-top: 16px;
}

.title {
  font-size: 16px;
  letter-spacing: -0.5px;
}

.content {
  display: flex;
  flex-direction: column;
}

.timelineContainer {
  position: relative;
  margin-bottom: 16px;
}

.timeline>div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 24px;
  position: relative;
}

.timelinePoints {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.timelinePoint {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelinePoint:first-child {
  align-items: flex-start;
}

.timelinePoint:last-child {
  align-items: flex-end;
}

.timelinePoint>:first-child {
  color: colorViolet;
  font-size: 14px;
  letter-spacing: -0.25px;
}

.timelinePoint>:last-child {
  color: colorShuttleGrey;
}

.text {
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.freeCancellation {
  background-color: colorPolar;
  color: colorCaribbeanGreen;
}

.freeCancellation::before, .freeCancellation::after, .partiallyRefundable::after, .nonRefundable::before, .nonRefundable::after {
  position: absolute;
  content: "";
  height: 30px;
  width: 2px;
  top: -3px;
}
.freeCancellation::before, .nonRefundable::before {
  left: 0;
  background-color: colorShuttleGrey;
}

.freeCancellation::after {
  right: 0;
  background-color: colorAmaranth;
}

.partiallyRefundable {
  background-color: colorBackgroundGrey;
  color: colorShuttleGrey;
}

.partiallyRefundable::after, .nonRefundable::after {
  right: 0;
  background-color: colorShuttleGrey;
}

.nonRefundable {
  position: relative;
  background-color: colorRemy;
  color: colorAmaranth;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.getByDate {
  transform: translateX(-10px);
}

.today {
  position: absolute;
  bottom: 18px;
  transform: translateX(-50%);
  white-space: nowrap;
  text-align: center;
}

.today::before {
  display: block;
  content: "";
  width: 2px;
  height: 34px;
  background-color: colorLightningYellow;
}

.today > span {
  color: colorLightningYellow;
}

.notice>span {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.notice>p {
  color: colorShuttleGrey;
  max-height: 90px;
  overflow-y: auto;
}

@media (min-width: smBreak) {
  .content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 16px;
  }

  .timelineContainer {
    align-self: flex-start;
    padding-bottom: 46px;
    margin-bottom: 0;
  }
}
