@value colorBlueRibbon from "../../common/colors/colors.css";

.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 1em auto;
}
.paginationItem {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 18px;
  min-width: 32px;
}
.paginationItem.dots:hover {
  background-color: transparent;
  cursor: default;
}
.paginationItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.paginationItem.selected {
  background-color: colorBlueRibbon;
  color: white;
}

.paginationItem .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.paginationItem .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.paginationItem .arrow.right {
  transform: rotate(45deg);
}

.paginationItem.disabled {
  pointer-events: none;
}

.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.disabled:hover {
  background-color: transparent;
  cursor: default;
}


