@value unit, mdBreak, transitionSpeed from "../../common/metrics.css";
@value colorBlackSqueeze from "../../common/colors/colors.css";
@value colorBlueRibbon from "../../common/colors/colors.css";
@value colorPrimaryGrey from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";

@value filtersPanelWidth: 340px;
@value collapsedFiltersPanelWidth: 32px;
@value topBarHeight: 48px;

.searchResults {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pageSectionContainer {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 32px;
}

.hotelResult {
  margin-bottom: 24px;
}

.searchBarContainer {
  background-color: colorBlackSqueeze;
}

.content {
  flex: 1;
}

.topBar {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}


.commission {
  width: 80px;
}

.commissionText {
  cursor: pointer;
  color: colorPrimaryGrey;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
}

.commissionInput {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}

.commissionIcon {
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}
.commissionIcon svg {
  width: 16px;
  height: 16px;
}

.inputTextReadOnly {
  background-color: white !important;
}

.inputTextReadOnly:hover {
  border-color: colorBlueRibbon !important;
}

.pagination {
  display: flex;
  flex-direction: row;
}

.pagination .paginationLink {
  margin-right: 10px;
  align-items: center;
  border-radius: 2px;
  color: colorBlueRibbon;
  background-color: transparent;
  min-width: 30px;
  padding: 5px 8px;
}

.pagination .paginationLink.active {
  background-color: colorBlueRibbon;
  border-radius: 5px;
  color: white;
  cursor: default;
}

.noData {
  font-size: 14px;
  text-align: center;
  margin: 3em 0;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.25px;
  color: colorShuttleGrey;
}

@media (min-width: mdBreak) {
  .pageSectionContainer {
    padding: 0;
    display: grid;
    grid-template-columns: filtersPanelWidth auto;
    grid-template-rows: topBarHeight auto;
    row-gap: calc(unit * 2);
    column-gap: unit;
  }

  .searchResults.filtersCollapsible .pageSectionContainer {
    transition: grid-template-columns transitionSpeed;
  }
  
  .searchResults.filtersCollapsed .pageSectionContainer {
    grid-template-columns: collapsedFiltersPanelWidth auto;
  }
  
  .searchBarContainer {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .topBar {
    justify-content: flex-start;
    grid-column: 2;
    grid-row: 1;
  }
}
