@value unit from "../../../../common/metrics.css";
@value colorShuttleGrey, colorViolet from "../../../../common/colors/colors.css";

.conditions {
  margin: calc(3 * unit) 0 calc(5 * unit);
  color: colorShuttleGrey;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
  font-feature-settings: 'liga' off;
}

.conditions h2 {
  padding-top: 40px;
  padding-bottom: 8px;
  color: colorViolet;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.75px;
}

.conditions h3 {
  padding-bottom: 4px;
  padding-top: 8px;
  color: colorViolet;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.conditions .subtitle {
  padding-bottom: 4px;
  color: colorViolet;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.conditions div:first-child {
  margin-right: calc(2 * unit);
}
