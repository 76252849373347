@value smBreak, unit from "../../../common/metrics.css";

.quickSearchRoot {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin: unit 0;
}

.quickSearchRoot>* {
    height: 36px;
}

.input {
    margin-right: 8px;
    height: 36px;
    max-width: 150px;
}

@media (min-width: smBreak) {
    .quickSearchRoot {
        margin: 0;
    }
}
