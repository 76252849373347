@value colorPrimaryGrey from "../../../../../../common/colors/colors.css";

.image {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    transition: background-image 0.2s linear;
    background-size: cover;
    background-position: center;
}

.navButton {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0 , 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.navButton svg {
    width: 14px;
    height: 14px;
}

.navButton path {
    fill: rgba(255, 255, 255, 0.9);
}

.navButton.left {
    left: 8px;
}

.navButton.right {
    right: 8px;
}

.navButton:hover {
    background-color: rgba(0, 0, 0 , 0.7);
}

.navButton:active {
    background-color: black;
}

.imageSlashIcon {
    fill: colorPrimaryGrey;
}
