@value medium as mediumFont from "../../common/metrics.css";
@value transitionSpeed from "../../common/metrics.css";
@value heavyShadow from "../../common/metrics.css";
@value targetSize from "../../common/metrics.css";

@value colorPrimary from "../../common/colors/colors.css";
@value colorLightningYellow from "../../common/colors/colors.css";
@value colorSecondary from "../../common/colors/colors.css";
@value colorPictonBlue from "../../common/colors/colors.css";
@value colorLoblolly from "../../common/colors/colors.css";
@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorFoam from "../../common/colors/colors.css";

@value radiusRegular: 8px;
@value radiusSmall: 4px;

@value fontSizeLarge: 16px;
@value fontSizeSmall: 12px;

@value regularPadding: 4px 12px;
@value largePadding: 12px 24px;
@value smallPadding: 0 6px;

/* Base Component Style */

.button {
  position: relative;
  color: white;
  background: colorPrimary;
  border-radius: radiusRegular;
  padding: regularPadding;
  font-weight: mediumFont;
  user-select: none;
}

/* Button States & Transitions */

.button {
  transition: background-color transitionSpeed, box-shadow transitionSpeed, filter transitionSpeed;
}

.button:hover:not([disabled]) {
  box-shadow: heavyShadow;
  transition: background-color transitionSpeed, box-shadow transitionSpeed, filter transitionSpeed;
}

.button:active:not([disabled]) {
  transition: box-shadow transitionSpeed;
  filter: brightness(1.3);
}

.medium:active:not([disabled]),
.low:active:not([disabled]) {
  filter: brightness(0.9);
}

.button:hover:active {
  transition: filter 0ms;
}

.button:focus-visible {
  border: 2px solid colorLightningYellow;
  margin: -2px;
}

/* Target are for clicks */

.target {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: targetSize;
  min-height: targetSize;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* Opacity is set to 0 to ensure WCAG compliance. This is not redundant. */
  opacity: 0;
  /* Provide a background to the target. Useful for debugging.  */
  /* background-color: rgba(219, 20, 60, 0.1); */
}

/* Handle Text Overflow, Single line with ellipsis */

.button {
  max-width: 100%;
}

.container {
  max-width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Size Variants */

.large {
  font-size: fontSizeLarge;
  padding: largePadding;
}

.small {
  border-radius: radiusSmall;
  font-size: fontSizeSmall;
  padding: smallPadding;
}

/* Emphasis Variants */

.medium {
  background-color: colorSecondary;
  color: colorPrimary;
}

.low {
  background-color: colorFoam;
  color: colorPictonBlue;
}

.empty {
  background-color: transparent;
  color: colorPrimary;
}

/* Disabled */

.button[disabled] {
  cursor: unset;
  color: colorLoblolly;
  background-color: colorCatskillWhite;
}
