@value unit, bold, medium, borderRadius, smallPrint, smBreak from "../../../common/metrics.css";

@value (
  colorCatskillWhite,
  colorPolar,
  colorShuttleGrey,
  colorCaribbeanGreen,
  colorLightningYellow
) from "../../../common/colors/colors.css";

@value thumbnailWidth: 120px;
@value thumbnailHeight: 80px;
@value nudge: 4px;
@value smallIconSize: 14px;

.roomRow {
  display: flex;
  flex-direction: column;
}

.skeletonThumbnail,
.thumbnail {
  width: 100%;
  object-fit: cover;
  background-position: center;
  height: thumbnailHeight;
  border-radius: borderRadius;
}

.noImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colorCatskillWhite;
  width: thumbnailWidth;
  height: thumbnailHeight;
}

.informationColumn {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 8px 0 24px;
}

.name {
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 24px;
}

.sleepColumn {
  text-align: center;
  display: none;
}

.sleepColumn svg:not(:last-child) {
  margin-right: nudge;
}

.ratesColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: flex-end;
  gap: unit;
  margin-top: 12px;
}

.bedItem {
  display: flex;
  gap: unit;
  align-items: center;
  color: colorShuttleGrey;
  font-size: smallPrint;
}

.priceDetail {
  display: flex;
  align-items: baseline;
  color: colorShuttleGrey;
}

.priceDetail:not(:first-child) {
  margin-top: unit;
}

.priceLabel {
  font-size: smallPrint;
  margin-right: unit;
}

.skeletonSleeps {
  width: 1em;
  margin-right: 0.5em;
}

.skeletonFull {
  width: 80%;
  margin-bottom: 0.5em;
 }

 .skeletonHalf {
  width: 50%;
  margin-bottom: 0.5em;
 }

.noCancellationBadge {
  align-self: flex-start;
}

.cancellationRadio {
  max-width: unset;
  line-height: 18px;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  line-height: 18px;
  margin-bottom: 12px;
}

.radioGroup>:not(:last-child) {
  margin-bottom: 8px;
}

.radioGroup>div {
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
}

.priceDiff {
  font-size: 12px;
}

@media (min-width: smBreak) {
  .roomRow {
    display: grid;
    grid-template-columns: 120px 2fr 1fr 3fr 3fr 1fr;
    column-gap: calc(2 * unit);
  }

  .skeletonThumbnail,
  .thumbnail {
    width: thumbnailWidth;
  }

  .informationColumn {
    flex-direction: column;
    justify-content: flex-start;
  }

  .sleepColumn {
    display: block;
  }

  .radioGroup {
    margin-bottom: 0;
  }
  
  .ratesColumn {
    grid-column-start: unset;
    grid-column-end: unset;
    justify-content: flex-start;
    row-gap: calc(2 * unit);
    margin-top: 0;
  }
}
