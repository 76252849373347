@value colorMystic from "../../common/colors/colors.css";

.dialogWrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
  background-color: #80808045;
}

.dialog {
  background: white;
  border-radius: 10px;
}

.dialogBody {
  padding: 24px 32px;
}

.title {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-feature-settings: 'liga' off;
}

.text {
  font-size: 14px;
}

.open {
  display: flex;
}

.dialogFooter {
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid colorMystic;
}

.rightButton {
  margin-left: 8px;
}
