@value unit from "../../common/metrics.css";
@value colorShuttleGrey from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";

.roomItem {
  display: flex;
  justify-content: space-between;
  gap: calc(3 * unit);
}

.roomLabel {
  white-space: nowrap;
  color: colorViolet;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
  letter-spacing: -0.5px;
}

.inputs {
  width: calc( 100% - 100px );
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: colorShuttleGrey;
  white-space: nowrap;
}

.inputs .input {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.text {
  margin-right: calc(2 * unit);
}

.childrenAges {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: unit;
  margin-top: 20px;
}

.childrenAges > label  {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.childrenAges select  {
  border: 1px solid #E1E8ED;
  border-radius: 8px;
  padding: 12px 16px;
  color: #080837;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 13px;
  width: 106px;
}

.childrenAges .childrenAgeTitle {
  margin-bottom: 4px;
  line-height: 16px;
}

.inputText {
  border: none;
}

.inputText.readOnly {
  background-color: unset;
}

.peopleCounter .counterInput {
  width: 50px;
  border: none;
  background-color: transparent;
}
