@value (
    colorRemy,
    colorAmaranth,
    colorPolar,
    colorCaribbeanGreen,
    colorSerenade,
    colorLightningYellow,
    colorLightOrange,
    colorCatskillWhite,
    colorShuttleGrey,
    colorViolet,
    colorAqua
) from "../../common/colors/colors.css";

.badge {
    display: flex;
    align-items: center;
    line-height: 18px;
    padding: 0 8px;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.5625rem;
    font-weight: 700;
    letter-spacing: 2px;
    white-space: nowrap;
    height: 18px;
}

.badge > svg {
    height: 12px;
    width: 12px;
}

.badge > svg:first-child {
    margin-right: 5px;
}

.badge > svg:last-child {
    margin-left: 5px;
    margin-right: 0;
}

.orange {
    color: colorLightningYellow;
    background-color: colorLightOrange;
}

.orange path {
    fill: colorLightningYellow;
}

.purple {
    color: #7555F8;
    background-color: #F1EEFE;
}

.purple path {
    fill: #7555F8;
}

.red {
    color: colorAmaranth;
    background-color: colorRemy;
}

.red path {
    fill: colorAmaranth;
}

.green {
    background: colorPolar;
    color: colorCaribbeanGreen;
}

.green path {
    fill: colorCaribbeanGreen;
}

.yellow {
    background: colorSerenade;
    color: colorLightningYellow;
}

.yellow path {
    fill: colorLightningYellow;
}

.grey {
    background: colorCatskillWhite;
    color: colorShuttleGrey;
}

.grey path {
    fill: colorShuttleGrey;
}

.black {
    background: colorViolet;
    color: white;
}

.black path {
    fill: white;
}

.lightBlue {
    background: colorAqua;
    color: #0FA6ED;
}

.lightBlue path {
    fill: #0FA6ED;
}
