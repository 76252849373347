@value transitionSpeed from "../../common/metrics.css";
@value colorViolet from "../../common/colors/colors.css";

.inputLookup {
    position: relative;
    display: inline-block;
    width: fit-content;
}

.inputText {}

/* Select List */

.selectList {
    position: absolute;
    z-index: 1001;
    background-color: white;
    top: 64px;
    left: 0;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(41, 43, 50, 0.12);
    /* @todo this will need to be revisited when global breakpoint margins are resolved */
    max-width: calc(100vw - 32px);
    color: colorViolet;
    min-width: 100%;
    opacity: 0;
    visibility: hidden;
}

.open .selectList {
    visibility: visible;
    opacity: 1;
    transition: opacity transitionSpeed;
}

