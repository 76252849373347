@value unit, mdBreak from "../../common/metrics.css";
@value colorMystic, colorPrimary from "../../common/colors/colors.css";

.searchBar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc( 2 * unit);
}

.inputLookup, .inputDate, .inputText, .inputSelect, .searchButton {
  width: 100%;
}

.locationPicker {
  grid-column-start: 1;
  grid-column-end: 3;
}

.inputDateRange {
  grid-column-start: 1;
  grid-column-end: 3;
}

.checkOut {
  grid-column-start: 2;
  grid-column-end: 3;
}

.guestsPicker, .searchButton {
  grid-column-start: 1;
  grid-column-end: 3;
}

@media (min-width: mdBreak) {
  .searchBar {
    grid-template-columns: 4fr 4fr 3fr 1fr;
    grid-gap: calc( 2 * unit);
  }
  .locationPicker, .inputDateRange, .guestsPicker, .searchButton {
    grid-column-start: unset;
    grid-column-end: unset;
  }
}
