/*
 * The definitive list of colors to be used in the app. Colors should never
 * be referenced by hex code outside this file. All colors should be represented in
 * the list below.
 *
 * Please name colors with the appropriate prefix to avoid conflicts with
 * native browser colors:
 *
 * All new colors should appear in storybook
 */

 /** Brand colors from Figma */
 /**
  * IMPORTANT: All brand colors have been generated using
  * http://chir.ag/projects/name-that-color. When adding new
  * colors please use this.
  */
@value colorHawkesBlue: #EAEFFE;
@value colorBlueRibbon: #2B5FF5;
@value colorPictonBlue: #48ABEE;
@value colorLightningYellow: #FDBC22;
@value colorBlackSqueeze: #F2F7FA;
@value colorLightGrey: #E4E4E4;
@value colorShuttleGrey: #5C6770;
@value colorViolet: #080837;
@value colorPolar: #E6F9F4;
@value colorCaribbeanGreen: #00C48C;
@value colorFlushOrange: #FB8700;
@value colorAmaranth: #F2335C;
@value colorCatskillWhite: #F1F6F9;
@value colorRemy: #FEEBEF;
@value colorLoblolly: #C5D0D8;
@value colorMystic: #E1E8ED;
@value colorFoam: #EDF7FD;
@value colorSerenade: #FFF3E6;
@value colorAqua: #E7F6FD;
@value colorLightOrange: #FFF8E9;

/*
 * IMPORTANT(jess): This color was not named using the color generator
 * as the color generator generated the color 'Polar' for this hex value which
 * has already been mapped to #E6F9F4 above.
 */
@value colorBackgroundGrey: #FBFDFE;

/** Aliases for more commonly used colors */
@value colorPrimary: colorBlueRibbon;
@value colorSecondary: colorHawkesBlue;
@value colorPrimaryGrey: colorShuttleGrey;
@value colorPrimaryYellow: colorLightningYellow;
@value colorPrimaryGreen: colorCaribbeanGreen;
/** Secondary color often used with primary green */
@value colorSecondaryGreen: colorPolar;
@value colorPrimaryLightBlue: colorPictonBlue;

 /** Protoapp colors */
 @value colorOrange: #ec5f00;
 @value colorBlue: #1700f8;
 @value colorGreen: #00a300;
 @value colorRed: #b71c1c;
 @value colorBlack: #000000;
 @value colorCobalt: #1574e0;
 @value colorWhite: #ffffff;
 @value colorGreen: #00a300;
 @value colorCharcoal: #4a4a4a;
 @value colorLead: #757575;
 @value colorAsh: #9b9b9b;
 @value colorSilver: #d8d8d8;
 @value colorMustard: #f3E100;
 @value colorDarkSeaGreen: #99a18c;
 @value colorDarkGray: #999999;
 @value colorGoldenrod: #fc9a2f;
 @value colorDarkViolet: #9800f8;
 @value colorGreenLight: #d3efd2;
 @value colorRedLight: #f3d8d8;
 @value colorOrangeLight: #FED8B1;
 @value colorCharcoalLight: #eeeeee;
 @value colorCustard: #fcf6aa;
 @value colorSky: #00ADB5;
