@value colorShuttleGrey from "../../common/colors/colors.css";
@value unit, smBreak from "../../common/metrics.css";

.booking {
  display: flex;
}

.searchButton {
  width: 100%;
  margin-bottom: 32px;
  height: 48px;
  border-radius: 12px;
}

.searchTabs .firstTab {
  width: 50%;
  flex-grow: unset;
}

.basicSearch>div {
  display: flex;
  flex-direction: column;
}

.basicSearch>:nth-child(1) {
  display: grid;
  column-gap: 16px;
}

.basicSearch>:nth-child(1)>:last-child {
  grid-column: span 2;
}

.emptyData {
  width: 100%;
  padding: 16px;
  text-align: center;
  font-size: 12px;
  color: colorShuttleGrey;
}

@media (min-width: smBreak) {
  .searchTabs {
    margin-bottom: calc(2 * unit);
  }

  .basicSearch>div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
  }

  .basicSearch>:nth-child(1)>:last-child {
    grid-column: span 2;
  }

  .basicSearch>:nth-child(2)>:first-child {
    grid-column: span 2;
  }

  .searchButton {
    width: 308px;
    margin-bottom: 48px;
  }
}
