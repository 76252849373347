@value colorPrimary from "../../../../../ui/common/colors/colors.css";

.view {
  border-radius: 8px;
  width: 308px;
  justify-self: flex-end;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 48px;
  background: rgba(8, 8, 55, 1);
}

.mapView {
  background: url('../../../../../assets/img/map-view-details-bg.png') no-repeat;
}

.listView {
  background: rgba(8, 8, 55, 1);
}

.viewButton {
  display: flex;
  justify-content: center;
  background: rgba(255,255,255, 1);
  border-radius: 4px;
  height: 24px;
  padding: 6px 16px;

  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorPrimary;
}
