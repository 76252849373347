.page {
  display: flex;
  flex-direction: column;
  margin: 20px;
  font-size: 14px;
}

.tablelink {
  color: blue
}

