/** Standard multiple for sizes and spacings */
@value unit: 8px;
/** Standard border radius */
@value borderRadius: 8px;
/** Standard border width */
@value borderWidth: 1px;

/** Breakpoints : Mobile first use `@media (min-width: smBreak)` */
@value smBreak: 600px;
@value mdBreak: 1024px;
@value lgBreak: 1440px;
@value xlBreak: 1920px;

/** WCAG Minimum Target Size */
@value targetSize: 44px;

/** Default speed of CSS Transitions */
@value transitionSpeed: 250ms;

/** Maximum width of the content area within the layout */
@value maxContentWidth: 1300px;

/** Standardised font weights */
@value regular: 300;
@value medium: 500;
@value bold: 600;

/** Font size variants */
@value smallPrint: 12px;

/** Base Icon Size */
@value baseIconSize: 32px;

/** Shadows */
@value lightShadow: 0px 4px 12px rgba(41, 43, 50, 0.08);
@value heavyShadow: 0px 4px 12px rgba(41, 43, 50, 0.16);
