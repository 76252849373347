
.fieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkIcon {
  margin-left: 10px;
}

.idlink {
  color: blue
}
