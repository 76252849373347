@value colorViolet from "../../../common/colors/colors.css";

.termsAndConditionsModal {
  width: 80%;
  height: 80%;
  max-width: 900px;
}

.termsAndConditions {
  font-size: 14px;
  line-height: 2em;
  overflow-y: auto;
  height: 100%;
  color: colorViolet;
}

.termsAndConditionsModal .title {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -1px;
  font-weight: 600;
  margin-bottom: 30px;
}

.termsAndConditionsModal p {
  font-weight: 500;
  color: black;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 24px;
  margin-bottom: 10px;
}

.termsAndConditionsModal h2 {
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
}
.termsAndConditionsModal h3 {
  font-size: 20px;
  font-weight: 600;
}