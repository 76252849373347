@value mdBreak from '../../../../common/metrics.css';

.currencyDropdown {
    margin-bottom: 24px;
}

@media (min-width: mdBreak) {
    .generalDetails {
        max-width: 524px;
    }

    .currencyDropdown {
        width: 250px;
    }
}

