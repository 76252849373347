
.skeleton {
  display: inline-block;
  width: 100%;
  height: 1em;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 2px;
}

.pulse {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
   
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}