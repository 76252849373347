@value colorPrimary from '../../common/colors/colors.css';

.errorFallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.logo {
    width: 200px;
    margin-bottom: 72px;
}

.text {
    font-size: 20px;
    margin-bottom: 48px;
    font-weight: 300;
    color: colorPrimary;
}

.text a {
    text-decoration: underline;
}

.bottomText {
    margin-top: 10%;
}

.refreshButton {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.refreshButton svg {
    transform: scale(1.5);
}
