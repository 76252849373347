@value colorPictonBlue, colorMystic, colorLightOrange, colorLightningYellow, colorViolet from "../../../../common/colors/colors.css";
@value smBreak from "../../../../common/metrics.css";

.agentPaymentInfo {
    border-left: 1px solid colorMystic;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
}

.agentPrices {
    flex-grow: 1;
    align-items: flex-end;
}

.priceLabel {
    line-height: 16px;
    font-weight: 700;
    font-size: 9px;
    letter-spacing: 2px;
    margin-right: 4px;
}

.lastUpdated {
    color: colorPictonBlue;
    margin-top: 8px;
    line-height: 12px;
    margin-bottom: 12px;
}

@media (min-width: smBreak) {
    .agentPaymentInfo {
        padding: 16px 16px 16px 20px;
        flex-direction: row;
        justify-content: flex-end;
    }
    
    .agentPaymentInfo.collapsed {
        flex-direction: column-reverse;
    }
    
    .agentPrices {
        gap: 12px;
    }

    .agentPrices.collapsed {
        width: 100%;
    }

    .lastUpdated {
        text-align: right;
        margin-bottom: 0;
    }

    .agentPrices.collapsed .lastUpdated {
        margin-top: 0;
    }
}
