  .heading {
    display: inline-block;
  }

  .panel {
    float: right;
  }

  .columnHeaderContent {
    display: flex;
  }

  .columnLabel {
    flex: 1;
  }

  .columnConfigIcons {
  }

  .activeColumnConfigIcon {
    color: blue;
  }

  .pageLocation {
    margin: 5px;
  }

  .columnPropsContainer {
    position : relative;
    /** TODO(timd) work out how to position this below the header cell without guessing
     pixel offsets */
    top: 10px;
    left: 0px;
  }

  .columnProps {
    width: 300px;
    position: absolute;
    background: #F9FAFB;
    top: 15px;
    left: -8px;
    z-index: 1;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .editCellContainer {
    position: relative;
  }

  .editCell {
    position: absolute;
    background: #F9FAFB;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  /**
   * Minimal style for a table cell, so that hover content can be positioned.
   * This style is assumed, so custom themes should make sure to apply it.
   */
  .cell {
    /** Reset the padding, as the cell content will be responsible for its own padding. */
    padding: 0;

    /** Prevent wrapping, as a lot of logic makes assumptions about cell height */
    white-space: nowrap;

    /** We can't put this here as it is then not overridable (thanks to aphrodite's use of !important)
     and we need to override it to 'absolute' for fixed columns to work.
     So, we need to make sure this is explicitly set as a style in each cell...
     position: 'relative'; */
  }

  .cellContent {
    /** Padding value copied from semantic... ideally this would be done via external css somehow. */
    padding: '0.75em';
  }

  .cellPopupContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  /** Don't use going forward - see comment below: */
  .hackCellPopunder {
    position: absolute;
    left: 0;
    top: 40px; /** HACK(dan) to make it go under the cell.  I think we'll do away with this though,
                  I'm rejigging the DOM/CSS to optimise of lined-up overlay.  To position at the bottom
                  without a hack, use an extra 0-height container with bottom:0, and then place the popup
                  within that.
                 */
    background: #F9FAFB;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  .columnFilterInput {
    width: 100%;
    margin: 5px 0px 0px 0px;
  }
