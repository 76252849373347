@value smBreak from "../../../../common/metrics.css";

.hotelResult:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: smBreak) {
  .hotelResult:not(:last-child) {
    margin-bottom: 24px;
  }
}
