@value (
    colorViolet,
    colorShuttleGrey,
    colorBlackSqueeze,
    colorWhite
) from "../../../common/colors/colors.css";

@value mdBreak from "../../../common/metrics.css";

@value amenitiesListWidth: 848px;

.root {
    border: 1px solid colorBlackSqueeze;
    border-radius: 8px;
    padding: 16px;
    height: fit-content;
    background-color: colorWhite;
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
}
.amenityBlock {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 16px;
}
.blockTitle {
    color: colorViolet;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}
.blockValue {
    color: colorShuttleGrey;
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
}

@media (min-width: mdBreak) {
    .root {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        padding: 32px;
        width: amenitiesListWidth;
    }
    .amenityBlock {
        padding-right: 32px;
    }
}
