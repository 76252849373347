@value colorPrimary from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";
@value transitionSpeed from "../../common/metrics.css";
@value unit from "../../common/metrics.css";

.inputSelect {
    position: relative;
    width: fit-content;
    outline: none;
}

.inputText {
    background-color: white !important;
    padding-right: 24px;
    position: relative;
}

.inputText:hover {
    border-color: colorPrimary;
}

.inputText::after {
    position: absolute;
    content: "";
    top: 22px;
    right: 22px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid colorShuttleGrey;
    display: block;
}

.input {
    cursor: pointer;
}

.openToggle svg {
    transform: rotate(180deg);
    transition: transform transitionSpeed;
}

.closedToggle svg {
    transform: rotate(0);
    transition: transform transitionSpeed;
}

.open {
    border-color:colorPrimary;
}

.open::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: unset;
    border-bottom: 5px solid colorShuttleGrey;
}

/* Select List */

.selectList {
    position: absolute;
    z-index: 1001;
    background-color: white;
    top: calc(100% + 4px);
    left: 0;
    max-height: 25vh;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
    max-width: calc(100vw - 32px);
    max-width: calc(100dvw - 32px);
    color: colorViolet;
    display: none;
    border: 1px solid colorMystic;
}

.open .selectList {
    display: flex;
    flex-direction: column;
    transition: opacity transitionSpeed;
}

.selectListInner {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 100%;
}

/* Select List Options */

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    line-height: 2em;
    cursor: pointer;
    padding: 8px 16px;
}

.option:hover {
    max-width: calc(100% + 16px);
    background-color: #EAEFFE;
}

.option > *:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
}

.option > *:last-child {
    margin-left: 8px;
}

.label {
    font-size: 11px;
    line-height: 12px;
    color: colorShuttleGrey;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: unit;
    font-weight: 700;
    white-space: nowrap;
}

.displayValue {
    position: absolute;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    bottom: 12px;
    left: 16px;
    z-index: 1;
    pointer-events: none;
    color: colorViolet;
    font-weight: 500;
}

.searchInput {
    height: 35px;
    border-radius: 6px;
    margin-bottom: 10px;
    flex: 0 0 auto;
}
.searchInput svg {
    width: 16px;
}
