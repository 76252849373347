@value smBreak from '../../common/metrics.css';

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    width: 100%;
    box-shadow: 0 4px 8px rgba(41, 43, 50, 0.08);
    margin-bottom: 32px;
    border-radius: 12px;
    background-color: white;
}

@media (min-width: smBreak) {
    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 40px;
    }
}
