@value (
colorMystic,
colorLightOrange,
colorLightningYellow,
colorPictonBlue,
colorViolet,
colorShuttleGrey
) from "../../../../common/colors/colors.css";

.bookingItemContainer {
  border: 1px solid colorMystic;
  border-radius: 8px;
  padding-left: 27px;
  position: relative;
  background-color: white;
}

.bookingItem {
  background-color: white;
  border-radius: 8px;
  border: 1px solid colorMystic;
  margin-bottom: 15px;
}

.bookingItem:last-child {
  margin-bottom: 0;
}

.summary {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.shortInfo {
  margin-right: 10px;
}
.shortInfo>:first-child {
  margin-bottom: 12px;
}

.selectionCheckboxProxy {
  border: 1px solid colorShuttleGrey;
}

.typeBadge {
  font-size: 9px;
}

.hotelName {
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 500;
}

.hotelLocation {
  line-height: 16px;
  margin-top: 4px;
}

.hotelLocation>:first-child {
  margin-right: 6px;
}

.checkIn {
  margin-right: 10px;
}

.text {
  color: colorViolet;
}

.price {
  display: flex;
  align-items: center;
}

.priceValue {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.75px;
  color: colorViolet;
  margin-right: 4px;
}

.currency {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: colorShuttleGrey;

}
