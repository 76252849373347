@value (
    colorMystic,
    colorCatskillWhite,
    colorViolet,
    colorBackgroundGrey,
    colorPolar
) from '../../../../common/colors/colors.css';

@value smBreak, unit from '../../../../common/metrics.css';

.financialDetails {
    border-top: 1px solid colorMystic;
    padding: 0 calc(unit * 2) unit calc(unit * 2);
}

.gridContainer {
    display: none;
    font-size: 12px;
    color: colorViolet;
}

.gridContainer>:first-child {
    font-weight: 700;
    background-color: colorCatskillWhite;
}

.gridRow {
    grid-template-columns: 2fr 2fr 3fr 5fr 1fr;
}

.balanceRow {
    grid-template-columns: 2fr 2fr 7fr 1fr 1fr;
    font-weight: 700;
}

.gridRow, .balanceRow {
    display: grid;
    padding: unit calc(unit * 2);
    grid-column-gap: 60px;
}

.gridRow>:nth-child(4), .gridRow>:nth-child(5), .balanceRow>* {
    text-align: right;
    white-space: nowrap;
}

.faded {
    background-color: colorBackgroundGrey;
}

.green {
    background-color: colorPolar;
}

.cardsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.financialCard {
    display: flex;
    flex-direction: column;
    padding: unit;
    border-radius: unit;
}

.financialCard>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.financialCard>div>:last-child {
    text-align: left;
}

.cardsList .balanceRow {
    grid-template-columns: 2fr 1fr 1fr;
    padding: unit;
}

@media (min-width: smBreak) {
    .gridContainer {
        display: block;
    }
    
    .cardsList {
        display: none;
    }
}
