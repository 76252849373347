@value colorPrimary from "../../../../common/colors/colors.css";
@value mdBreak from "../../../../common/metrics.css";

.list {
    display: none;
    width: 100%;
    margin-top: 22px;
    overflow-x: auto;
    padding: 2px;
    scroll-behavior: smooth;
}

.list.vertical {
    flex-direction: column;
    height: 90vh;
    width: 210px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0;
}

.list>li {
    height: 110px;
    width: 162px;
    min-width: 162px;
    border-radius: 8px;
    cursor: pointer;
    background-size: cover;
    overflow: hidden;
} 

.list.vertical>li {
    min-height: 110px;
}

.list>li.selected {
    outline: 2px solid colorPrimary;
}

.list>li:hover>img {
    transform: scale(1.2);
}

.list>li:not(:last-child) {
    margin-right: 24px;
}

.list.vertical>li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
}

.list>li>img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
}

@media (min-width: mdBreak) {
    .list {
        display: flex;
    }
}
