@value unit from "../../common/metrics.css";
@value colorPrimaryYellow from "../../common/colors/colors.css";

.starIcon path {
  fill: colorPrimaryYellow;
}
.halfStar.xs svg {
  width: 11px;
  height: 12px;
}

.ratingSection {
  margin-top: unit;
}
