@value colorViolet, colorLoblolly from "../../common/colors/colors.css";
@value smBreak from "../../common/metrics.css";

@value wrapGap: 8px;
@value crumbMargin: 12px;

.breadcrumb {
    font-size: 18px;
    margin-top: calc(-1 * wrapGap); /* Some magic to space wrapped items only, pull everything up */
}

.breadcrumb li {
    display: inline-block;
    margin-right: crumbMargin;
    margin-top: wrapGap; /* The other part of the magic; pull items down, first row back to where it started, subsequent rows have space */
    color: colorLoblolly;
}

.breadcrumb li::after {
    content: '/';
    margin-left: crumbMargin;
}

.breadcrumb li:last-child {
    margin-right: 0;
    color: colorViolet;
    font-weight: 500;
}

.breadcrumb li:last-child::after {
    content: none;
    margin-left: 0;
}

@media (min-width: smBreak) {
    .breadcrumb {
        font-size: 24px;
    }
}
