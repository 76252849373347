@value colorShuttleGrey, colorViolet from "../../../common/colors/colors.css";

.bankTransferLayout {
  display: block;
}

.bankTransfer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.paymentProperties,
.paymentMethod {
  flex-grow: 1;
}

.paymentProperties {
  padding-right: 15px;
}
.paymentProperties .row {
  margin-bottom: 4px;
}
.paymentProperties .row .title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: colorShuttleGrey;
  padding: 4px 12px 4px 4px;
}
.paymentProperties .row .value {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: colorViolet;
  padding: 4px;
}
