@value mdBreak from '../../../common/metrics.css';

.passwordSettings {
    width: 100%;
}

.saveButton {
    width: 100%;
    height: 40px;
    border-radius: 12px;
}

.hint {
    margin-bottom: 12px;
    color: #5C6770;
}

@media (min-width: mdBreak) {
    .passwordSettings {
        max-width: 480px;
    }
    .saveButton {
        width: 124px;
    }
}
