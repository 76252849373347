@value colorViolet, colorCatskillWhite, colorShuttleGrey from "../../../../common/colors/colors.css";

.newComment {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 4fr 1fr;
  max-height: 0;
  transition: max-height 200ms;
  overflow: hidden;
  margin-bottom: 0;
}

.newComment.opened {
  max-height: 500px;
  margin-bottom: 36px;
}

.bookingListWrapper {
  width: 100%;
  flex: 1 1 100%;
}

.bookingListWrapper .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 15px;
}

.bookingList {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-height: 120px;
  overflow-y: auto;
}

.bookingList .bookingCheckbox:not(:last-child) {
  margin-bottom: 8px;
}

.newComment textarea {
  font-size: 14px;
  height: 100%;
}

.newComment .footer {
  width: 100%;
  padding: 12px 0 0;
  display: flex;
  flex-direction: column;
}

.newComment .footer>:first-child {
  margin-bottom: 16px;
}

.commentInput {
  height: 100%;
}

.selectAll {
 font-weight: 700;
  margin: 16px 0 32px;
}
