@value colorWhite from "../../../common/colors/colors.css";
@value colorMystic from "../../../common/colors/colors.css";
@value colorShuttleGrey from "../../../common/colors/colors.css";
@value colorPrimary from "../../../common/colors/colors.css";
@value unit, mdBreak from "../../../common/metrics.css";

.navSettings {
  display: flex;
  justify-content: space-around;
  height: fit-content;
  border: 1px solid colorMystic;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  color: colorShuttleGrey;
  font-weight: 500;
  line-height: 24px;
  background-color: colorWhite;
  gap: 20px;
}

.navLink {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  color: colorShuttleGrey;
  gap: 10px;
  padding: 8px 0;
}

.navLink svg path {
  fill: colorShuttleGrey;
}

.navLink svg {
  width: 18px;
  height: 18px;
}

.navLink.selected {
  color: colorPrimary;
  border-bottom: 2px solid colorPrimary;
}
.navLink.selected svg path {
  fill: colorPrimary;
}

.navLink span {
  display: none;
}

@media (min-width: mdBreak) {
  .navSettings {
    display: flex;
    flex-direction: column;
    width: 270px;
    max-width: 270px;
    min-width: 270px;
    justify-content: flex-start;
    padding: calc(3 * unit);
  }
  
  .navLink {
    justify-content: flex-start;
    padding: 0;
  }
  
  .navLink span {
    display: inline;
  }

  .navLink.selected {
    border-bottom: none;
  }
}
