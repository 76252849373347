@value mdBreak from "../../../common/metrics.css";
.notificationBar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  z-index: 11;
}

@media (min-width: mdBreak) {
  .notificationBar {
    left: unset;
  }
}
