@value colorBlackSqueeze, colorShuttleGrey from "../../../../common/colors/colors.css";
@value smallPrint, unit, maxContentWidth, mdBreak from "../../../../common/metrics.css";

.stayDetailsBarWrapper {
    background-color: colorBlackSqueeze;
}

.stayDetailsBar {
    padding: calc(2 * unit);
    max-width: maxContentWidth;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: unit;
}

.detail {
    flex: 1;
}

.detail:last-of-type {
    margin-right: 0;
}

.detailValue {
    font-size: 16px;
}


.detail.large {
    flex-basis: 200px;
}

.detail.small {
    flex-basis: 92px;
}

.detail label {
    color: colorShuttleGrey;
    font-size: smallPrint;
}

@media (min-width: mdBreak) {
    .stayDetailsBar {
        display: flex;
        align-items: center;
        gap: calc(unit * 2);
    }
}
