@value colorShuttleGrey, colorMystic, colorViolet, colorRemy from "../../../common/colors/colors.css";

.unavailableItemsModal {
  width: 80%;
  height: 80%;
  max-width: 700px;
}

.unavailableItemsInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: colorViolet;
  margin-bottom: 30px;
}

.errorMessage {
  padding: 24px;
  width: 100%;
  background: colorRemy;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 20px;
  display: flex;
}

.errorIcon {
  margin-right: 10px;
}

.bolder {
  font-weight: bold;
  margin-top: 20px;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 16px;
}

.modalFooter button {
  margin-right: 16px;
}
.modalFooter button:last-child {
  margin-right: 0;
}

.bookingList {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow-y: auto;
}
