@value (
  colorBlackSqueeze,
  colorViolet,
  colorShuttleGrey
 ) from "../../../../common/colors/colors.css";

@value mdBreak from "../../../../common/metrics.css";

.list {
    display: none;
    row-gap: 8px;
}

.list a {
    color: inherit;
    text-decoration: inherit;
}

.listHead, .listBody > * {
    display: grid;
    grid-template-columns: 20% 20% 18% 11% 14% 3%;
    grid-column-gap: 16px;
    padding: 4px 24px 4px 16px;
}

.listHeadGap {
    padding-right: 34px;
}

.dateAndType>:first-child {
    font-size: 12px;
    font-weight: 400;
    color: colorShuttleGrey;
}

.listHead {
    color: colorShuttleGrey;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 2px;
}

.listHead>:nth-child(5) {
    text-align: right;
}

.listBody {
    display: flex;
    flex-direction: column;
    max-height: 425px;
    overflow-y: auto;
}

.booking {
    cursor: pointer;
}

.booking:hover {
    background-color: rgba(197, 208, 216, 0.3);
}

.bookingColumn {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.bookingColumn:not(:nth-child(3)) {
    padding-top: 25px;
}

.arrow {
    align-items: flex-end;
}

@media (min-width: mdBreak) {
    .list {
        display: grid;
    }
}
