@value (
    unit,
    borderRadius,
    borderWidth,
    bold,
    mdBreak
) from "../../../common/metrics.css";
@value (
    colorMystic,
    colorShuttleGrey,
    colorCaribbeanGreen,
    colorAmaranth
) from "../../../common/colors/colors.css";

@value pad: calc(2 * unit);
@value rightColumnWidth: calc(52 * unit);

.detailsContainer,
.responsive,
.rightColumn,
.leftColumn {
    display: flex;
    flex-direction: column;
    gap: calc(unit * 2);
}

.selectedPackage {
    margin-top: 0;
}

.quoteText {
    font-weight: bold;
    margin-bottom: calc(0.5 * unit);
}

.fullWidth {
    width: 100%;
}

@media (min-width: mdBreak) {
    .responsive {
        flex-direction: row;
    }
    .conditions {
        max-width: calc(100% - (rightColumnWidth + pad));
    }
    .leftColumn {
        max-width: calc(100% - (rightColumnWidth + pad));
        flex-grow: 1;
    }
}
