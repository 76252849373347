@value unit, smBreak from "../../common/metrics.css";
@value colorPrimary, colorViolet, colorLoblolly from "../../common/colors/colors.css";


@value profileImageSize: 24px;

@value logoMinWidth: 81px;
@value logoMinHeight: 16px;

.header {
    background-color: white;
    color: colorViolet;
}

.pageSectionContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    column-gap: calc(unit * 2);
}

.logo {
    margin: calc(3 * unit) 0;
    margin-right: calc(4 * unit);
    min-width: logoMinWidth;
    min-height: logoMinHeight;
}

@media (min-width: smBreak) {
    .pageSectionContainer {
        flex-direction: row;
    }
}

@media (min-width: smBreak) {
    .headerContainer {
        flex-direction: row;
        align-items: center;
    }

    .headerContainer > * {
        margin-right: calc(2 * unit);
        margin-bottom: 0;
    }

    .headerContainer > *:last-child {
        margin-right: 0;
    }
}

.navPrimary {
    align-self: flex-end;
}

.profileMenu {
    line-height: 0;
}

@media (min-width: smBreak) {
    .profileMenu {
        margin-left: calc(2 * unit);
    }
}
.profileImage {
    width: profileImageSize;
    height: profileImageSize;
    border-radius: calc(profileImageSize / 2);
}

.breadcrumb {
    display: flex;
    color: colorLoblolly;
    user-select: none;
}

.breadcrumb > li {
    display: flex;
    align-items: center;
}

.breadcrumb > li svg {
   margin: 0 8px;
}

.current {
    color: colorPrimary;
    font-weight: bold;
}
