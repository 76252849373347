.bookingDialogTable .label {
  padding-right: 10px;
  font-weight: 600;
}

.bookingDialogTable .value {
  font-weight: 500;
}

.bookingDialogTable .row {
  padding-bottom: 8px;
}