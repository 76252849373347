@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorBlueRibbon from "../../common/colors/colors.css";

.selectList {
    user-select: none;
    right: 0;
    width: 360px;
}

.hidden {
    display: none;
}

.inputText.readOnly {
    background-color: white;
}

.inputText.readOnly:hover {
    border-color: colorBlueRibbon;
}
