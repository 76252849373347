@value colorLoblolly, colorViolet, colorPrimary from "../../../common/colors/colors.css";
@value smBreak from "../../../common/metrics.css";

.itineraryBar {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.leftSection {
    gap: 25px;
    justify-content: space-between;
}

.block {
    display: flex;
    flex-direction: column;
}

.block>.label {
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 2px;
    font-weight: 700;
    color: colorLoblolly;
    margin-bottom: 5px;
    text-transform: uppercase;
}


.accommodationButton, .documentsButton {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: -0.25px;
    width: 157px;
    height: 32px;
    justify-content: center;
}

.documentsButtonInner {
    height: 100%;
    width: 100%;
}

.accommodationButton>:first-child {
    margin-right: 6px;
    width: 16px;
    height: 16px;
}

.documentsButton:hover {
    color: colorPrimary;
    text-decoration: none;
}

.itineraryId {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.75px;
    font-weight: 700;
    color: colorViolet;
}

@media (min-width: smBreak) {
    .itineraryBar {
        flex-direction: row;
        justify-content: space-between;
    }

    .itineraryBar>:last-child {
        align-items: flex-end;
    }

    .leftSection {
        justify-content: flex-start;
    }
}