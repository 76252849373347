@value colorViolet, colorShuttleGrey from "../../../../common/colors/colors.css";

.commentInfoModal {
  display: block;
  position: relative;
  width: 80%;
  max-width: 700px;
  max-height: 700px;
}
.commentInfo {
  width: 100%;
  height: 100%;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  color: colorViolet;
  font-size: 14px;
  line-height: 2em;
  overflow-y: auto;
}

.commentHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.commentHeader .title {
  font-weight: 700;
  font-size: 16px;
}

.commentHeader .date {
  color: colorShuttleGrey;
  font-weight: 500;
}

.commentText,
.bookingList {
  width: 100%;
  margin-bottom: 20px;
}

.commentText {
  overflow-wrap: break-word;
}

.bookingList .title {
  font-weight: 700;
}
