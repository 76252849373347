@value medium from "../../common/metrics.css";
@value unit from "../../common/metrics.css";
@value borderRadius from "../../common/metrics.css";
@value borderWidth from "../../common/metrics.css";

@value colorBlueRibbon from "../../common/colors/colors.css";
@value colorWhite from "../../common/colors/colors.css";
@value colorCaribbeanGreen from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";

.confirmationInput {
    border: borderWidth solid colorMystic;
    border-radius: borderRadius;
    /** NOTE(Barry): Need this so that the rounded corners don't get chopped off by the underlying element **/
    overflow: hidden;
    background-color: white;
    width: 100%;
}

.tabList {
    display: flex;
    flex-wrap: nowrap;
}

.confirmationTab {
    width: 100%;
    padding: unit;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmationTab.selected {
    background-color: colorBlueRibbon;
    cursor: auto;
}

.confirmationTabText {
    font-weight: medium;
    color: colorBlueRibbon;
    text-align: center;
    border-bottom: 1px solid colorBlueRibbon;
    padding: 0 15px;
    line-height: 24px;
}

.selected .confirmationTabText {
    color: colorWhite;
    border-bottom: 1px solid colorWhite;
}

.confirmBooking {
    width: 100%;
    background-color: colorCaribbeanGreen;
}

.confirmBookingWrapper {
    padding: calc(2 * unit);
}
