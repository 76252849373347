.page {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
  

.idrow {
    margin: 40px 0px 20px 5px;
    font-weight: bold;
    font-size: 14px;
}

.topbar1 {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
  }

.content {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.buttoncontainer {
    margin: 40px 0px 20px 5px;
}