.inputPlaceAutocomplete {
    position: relative;
    margin-bottom: 24px;
}

.inputInner {
    margin-bottom: 0;
}

.predictionsList {
    display: flex;
    flex-direction: column;
}


