@value (
  unit,
  borderRadius,
  borderWidth,
  mdBreak,
  bold
) from "../../../../../common/metrics.css";
@value colorMystic, colorViolet, colorShuttleGrey from "../../../../../common/colors/colors.css";

.guestFields {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.guestTitle {
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorShuttleGrey;
  margin-bottom: 16px;
}

.guestFields .genderInput {
  flex: 0 0 100px;
  margin-bottom: 0;
  width: 100%;
}

.genderInputDisplayValue {
  bottom: 36px;
}
.genderInputText::after {
  top: unset;
  right: 15px;
}

.guestFields .nameInput {
  flex-grow: 1;
}

.genderInput label {
  text-transform: initial;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.25px;
  font-feature-settings: 'liga' off;
  color: colorViolet;
  margin-bottom: 4px;
}

@media (min-width: mdBreak) {
  .guestFields {
    flex-direction: row;
    gap: calc(unit * 2);
  }

  .guestFields .genderInput {
    flex: 0 0 100px;
  }
}
