@value mdBreak from "../../../common/metrics.css";

.agenciesTable td:nth-of-type(1):before { content: "Name"; }
.agenciesTable td:nth-of-type(2):before { content: "Email"; }
.agenciesTable td:nth-of-type(3):before { content: "Commission"; }
.agenciesTable td:nth-of-type(4):before { content: "Address"; }
.agenciesTable td:nth-of-type(5):before { content: "Menu"; }



@media (min-width: mdBreak) {
    .agenciesTable tr {
        grid-template-columns: 5fr 5fr 2fr 9fr 1fr;
    }
}
