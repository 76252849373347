@value mdBreak from '../../../common/metrics.css';

.root {
    display: flex;
    flex-direction: column;
}

.shortInput {
    max-width: 200px;
}

.mediumInput {
    max-width: 479px;
}

.longInput {
    max-width: 632px;
}

.saveButton {
    width: 100%;
    height: 40px;
    border-radius: 12px;
}

@media (min-width: mdBreak) {
    .saveButton {
        width: 62px;
    }
}
