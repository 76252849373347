@value colorMystic, colorBlueRibbon, colorShuttleGrey from "../../../common/colors/colors.css";

.bookingSelected {
    border: 1px solid colorMystic;
    background-color: white;
    border-radius: 8px;
    min-width: 416px;
}

.summary {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.summary>:first-child {
    line-height: 16px;
}

.hotelData {
    padding: 0 8px 8px;
    border-bottom: 1px solid colorMystic;
}

.hiddenSection {
    border-bottom: 1px solid transparent;
    transition: max-height 500ms, border-bottom-color 200ms;
}

.hiddenSection.opened {
    border-bottom: 1px solid colorMystic;
    max-height: 200px;
}

.guestsAndDates {
    padding: 8px;
    display: flex;
}

.guestsAndDates>div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.guestsAndDates>div label {
    font-size: 9px;
    line-height: 10px;
    margin-bottom: 4px;
    letter-spacing: 2px;
    font-weight: 700;
    color: colorShuttleGrey;
}

.guestsAndDates>div span {
    font-size: 12px;
    line-height: 16px;
}

.dates span {
    font-weight: 700;
}

.checkOut {
    margin-top: 16px;
}

.ctaAndPrice {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ctaAndPrice span {
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
}

.ctaAndPrice > div {
    align-items: flex-end;
}

.expandButton {
    width: 95px;
    padding: 4px 10px 4px 6px;
}

.expandButtonInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.expandButton svg {
    width: 6px;
    height: 5px;
    transition: all 200ms;
}

.expandButton svg path {
    fill: colorBlueRibbon;
}

.expandButton.toggled svg {
    transform: rotate(180deg);
    transform-origin: center;
}

.priceLabel {
    margin-right: 4px;
    letter-spacing: 2px;
}
