@value colorBlackSqueeze from "../../../common/colors/colors.css";
@value unit, lgBreak from "../../../common/metrics.css";

@value coverImageWidth: 956px;

.hotelCoverImage {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-size: cover;
    position: relative;
}

.noHotelCoverImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: colorBlackSqueeze;
    border-radius: unit;
    cursor: unset;
}

@media (min-width: lgBreak) {
    .hotelCoverImage {
        width: coverImageWidth;
    }
}
