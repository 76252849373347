@value transitionSpeed from "../../common/metrics.css";

.toggleButton {}

.toggleButton svg {
  transition: transform transitionSpeed;
}

.toggleButton.on svg {
  transform: rotate(180deg);
}
