@value colorShuttleGrey from "../../../common/colors/colors.css";
@value unit, smBreak from "../../../common/metrics.css";

.advancedSearch > div {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
}

.inputLabel {
  font-size: 11px;
  line-height: 12px;
  color: colorShuttleGrey;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: unit;
  font-weight: 700;
}

.inputBox {
  margin-bottom: calc(3 * unit);
}

.inputText {
  width: 100%;
}

@media (min-width: smBreak) {
  .advancedSearch > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
  }

  .advancedSearch > :nth-child(2) > :last-child {
    grid-column: span 2;
  }

  .advancedSearch.disabledPayments > :nth-child(2) > :first-child {
    grid-column: span 2;
  }
}
