@value unit from "../../../common/metrics.css";
@value colorViolet from "../../../common/colors/colors.css";
@value colorPrimaryGrey from "../../../common/colors/colors.css";

@value smallerPrint: 11px;

.amountRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: calc(0.5 * unit);
  margin-top: calc(2 * unit);
}

.priceList {
  display: flex;
  flex-direction: column;
  font-size: smallerPrint;
}

.amountRow.sensitiveDataHidden {
  justify-content: flex-end;
}
.amountRow.sensitiveDataHidden > .priceList {
  display: none;
}

.amountRow:not(.sensitiveDataHidden) .moreInformationHint {
  display: none;
}

.moreInformationHint {
  align-self: flex-start;
  cursor: pointer;
  margin-right: 12px;
}
.moreInformationHint path {
  fill: colorPrimaryGrey;
}


.priceLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: colorViolet;
  margin-bottom: unit;
}

.amountDetail {
  align-self: flex-end;
  margin-bottom: auto;
}

.radioText {
  max-width: unset;
}
