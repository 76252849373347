@value (
    colorPictonBlue,
    colorPolar,
    colorCaribbeanGreen,
    colorViolet,
    colorMystic
) from "src/ui/common/colors/colors.css";

@value smBreak from "src/ui/common/metrics.css";

.backofficePaymentInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px 16px 20px;
    border-left: 1px solid colorMystic;
}

.paymentBadges {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 8px;
    align-self: flex-end;
}

.paymentBadges.collapsed {
    margin-right: 12px;
}

.paymentBadges.collapsed>:not(:last-child) {
    margin-bottom: 12px;
}

.backofficePrices {
    align-items: flex-end;
}

.backofficePrices.collapsed {
    width: 120px;
}

.priceLabel {
    margin-right: 4px;
    line-height: 12px;
}

.priceValue>:first-child {
    font-size: 10px;
}

.priceValue>:last-child {
    font-weight: 500;
}

.lastUpdated {
    color: colorPictonBlue;
    margin-top: 8px;
    line-height: 12px;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;
}


@media (min-width: smBreak) {
    .backofficePaymentInfo {
        flex-direction: row;
        justify-content: space-between;
    }

    .paymentBadges {
        align-self: flex-start;
    }
}
