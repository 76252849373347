@value colorShuttleGrey, colorViolet, colorMystic from '../../../../common/colors/colors.css';
@value smBreak from '../../../../common/metrics.css';

.bookingDetails {
    font-size: 12px;
    line-height: 1.5;
    border-top: 1px solid colorMystic;
    padding: 0 16px 16px;
    height: 100%;
}

.bookingDetails>:not(:last-child) {
    border-bottom: 1px solid colorMystic;
    padding: 16px 0;
}

.bookingDetails>:last-child {
    padding-top: 16px;
}

.row {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
}

.row.desktop {
    display: none;
}

.row.mobile:not(:last-child) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid colorMystic;
}

.roomDetails {
    display: none;
}

.label {
    letter-spacing: 2px;
    margin-bottom: 4px;
    font-size: 9px;
    font-weight: 700;
    color: colorShuttleGrey;
}

.text {
    color: colorViolet;
    font-weight: 700;
    word-break: break-all;
}

.importantInformation {
    color: colorShuttleGrey;
    line-height: 16px;
    font-weight: 500;
}

@media (min-width: smBreak) {
    .roomDetails {
        display: block;
    }
    
    .row {
        grid-template-columns: 2fr 2fr 2fr 3fr;
    }
    
    .row.desktop {
        display: grid;
    }

    .row.mobile {
        display: none;
    }
    
    .row.supplierSession {
        grid-template-columns: 2fr 2fr 5fr;
    }
}
