@value mdBreak from '../../../common/metrics.css';

.line {
    margin-bottom: 24px;
}

.phoneNumberLine {
    display: flex;
    align-items: flex-end;
}

.phoneNumberLine>:first-child {
    margin-right: 8px;
}

.phoneNumberLine>:last-child {
    margin-bottom: 0;
}

.saveButton {
    width: 100%;
    height: 40px;
    margin-top: 12px;
}

.countryFlag {
    max-height: 10px;
}

@media (min-width: mdBreak) {
    .line {
        width: 416px;
    }
    
    .phoneNumberLine>:first-child {
        margin-right: 8px;
    }

    .phoneNumberLine>:last-child {
        width: 264px;
    }
    .saveButton {
        width: 62px;
    }
}
