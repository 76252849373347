  @value unit, borderRadius from "../../common/metrics.css";

  .login {
    height: 100vh;
    background-image: url('../../../assets/img/_pPHgeHz1uk.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .loginContainer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100dvw - 24px);
    padding: 48px 32px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 12px 24px rgba(41, 43, 50, 0.25);
  }

  .message {
    min-height: 2em;
    opacity: 0;
    transition: opacity 800ms;
    color: crimson;
  }

  .show {
    opacity: 1;
    transition: opacity 300ms;
  }

  .button {
    padding: unit;
    border: 1px solid black;
    border-radius: borderRadius;
  }

  .loginContainer > * {
    margin-bottom: 16px;
  }

  .loginContainer > *:last-child {
    margin-bottom: 0;
  }

  .inputText {
    width: 100%
  }

  @media (min-width: 400px) {
    .loginContainer {
      max-width: 400px;
    }
  }
