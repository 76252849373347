@value mdBreak from "../../../common/metrics.css";

.backofficeUsers tr {
    grid-template-columns: 6fr 7fr 5fr 5fr 1fr;
}

.backofficeUsers td:nth-of-type(1):before { content: "Name"; }
.backofficeUsers td:nth-of-type(2):before { content: "Email"; }
.backofficeUsers td:nth-of-type(3):before { content: "User type"; }
.backofficeUsers td:nth-of-type(4):before { content: "Status"; }
.backofficeUsers td:nth-of-type(5):before { content: "Menu"; }

@media (min-width: mdBreak) {
    .backofficeUsers tr {
        grid-template-columns: 6fr 7fr 5fr 5fr 1fr;
    }
}
