@value colorViolet, colorCatskillWhite, colorBlueRibbon, colorShuttleGrey from "../../../common/colors/colors.css";
@value mdBreak, unit from "../../../common/metrics.css";

.paymentsTotals {
  width: 100%;
  margin-top: 60px;
}

.paymentsTotalsTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.75px;
  color: colorViolet;
  margin-bottom: calc(unit * 2);
}

.paymentsTable {
  display: none;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
}

.paymentsTable th {
  font-weight: 700;
  background: colorCatskillWhite;
}
.paymentsTable th,
.paymentsTable td {
  padding: unit calc(unit * 2);
}

.paymentsTable .summary {
  font-weight: 700;
  background: #FFFFFF;
}

.totalPrices {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.totalPrices .termsAndConditions {
  font-weight: 700;
  font-size: 12px;
  line-height: calc(unit * 2);
  text-decoration-line: underline;
  color: colorBlueRibbon;
  cursor: pointer;
  min-width: 200px;
}

.totalPrices  td {
  padding: calc(unit * 2);
}

.view {
  font-weight: 500;
  color: colorBlueRibbon;
  cursor: pointer;
}
.view:hover {
  text-decoration: underline;
}

.emptyData {
  width: 100%;
  padding: calc(unit * 2);
  text-align: center;
  font-size: 12px;
  color: colorShuttleGrey;
}

.paymentsList {
  display: flex;
  flex-direction: column;
  gap: unit;
}

.paymentsList>li {
  display: flex;
  flex-direction: column;
  padding: unit;
  border-radius: unit;
  background-color: #FFFFFF;
}

.paymentsList>li>div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.paymentsList>li>div:last-child {
  text-align: left;
}

@media (min-width: mdBreak) {
  .paymentsTable {
    display: table;
  }
  
  .totalPrices {
    flex-direction: row;
    justify-content: space-between;
    margin: calc(unit * 2) 0 60px;
  }

  .paymentsList {
    display: none;
  }
}
