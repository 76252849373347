@value unit from "../../common/metrics.css";
@value medium from "../../common/metrics.css";
@value smallPrint from "../../common/metrics.css";
@value colorShuttleGrey from "../../common/colors/colors.css";
@value colorCaribbeanGreen from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";
@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";
@value colorBlueRibbon from "../../common/colors/colors.css";

@value largeFieldWidth: 200px;
@value smallFieldWidth: 80px;
@value headerHeight: 56px;
@value tabHeaderDividerWidth: 2px;
@value smallerPrint: 11px;

.payment {

}

.paymentForm {
    padding: calc(2 * unit);
}

.largeField {
    flex: 1 1 100%;
}

.smallField {
    flex: 0 0 104px;
}

.field {
    display: block;
    margin-bottom: 0;
}

.headerTabs {
    text-align: left;
    line-height: headerHeight;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    letter-spacing: -0.25px;
    display: flex;
}
.headerTabs .tab {
    text-align: center;
    line-height: 56px;
    flex-grow: 1;
    color: colorShuttleGrey;
    border-bottom: 2px #F1F6F9 solid;
    cursor: pointer;
}
.headerTabs .tab.activeTab {
    color: colorViolet;
    border-bottom: 2px colorBlueRibbon solid;
    cursor: unset;
}

.divider {
    height: tabHeaderDividerWidth;
    background-color: colorCatskillWhite;
    margin: 0 calc(-2 * unit) calc(2 * unit);
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: calc(0.5 * unit);
}

.row:not(:last-child) {
    margin-bottom: calc(2 * unit);
}

.row > *:not(:last-child) {
    margin-right: calc(2 * unit);
}

.fieldLabel {
    color: colorShuttleGrey;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    margin-bottom: 4px;
}

.checkbox {
    font-size: smallPrint;
    line-height: 1.5;
    color: colorShuttleGrey;
}

.payNowButton {
    background-color: colorCaribbeanGreen;
}

.payNowButton,
.cancelButton {
    width: 100%;
}

.info {
    font-size: smallPrint;
    line-height: 1.5;
    color: colorShuttleGrey;
}
