@value unit from "../../common/metrics.css";
@value mdBreak from "../../common/metrics.css";

@value colorPrimary from "../../common/colors/colors.css";
@value colorPrimaryLightBlue from "../../common/colors/colors.css";
@value colorPrimaryGrey from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";

@value underlineHeight: 4px;
@value iconMargin: 8px;
@value iconSize: 17px;

.navPrimary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    user-select: none;
    flex-grow: 1;
    margin: unit 0;
}

.navPrimary > a {
    display: inline-flex;
    align-items: center;
    border-bottom: underlineHeight solid transparent;
    color: colorViolet;
    white-space: nowrap;
    padding: unit 0;
}

.navPrimary > a:hover {
    text-decoration: none;
    border-bottom: underlineHeight solid colorPrimary;
}

.navPrimary > a.current {
    color: colorPrimary;
    font-weight: bold;
}

.navPrimary svg  {
    display: inline-block;
    margin-right: iconMargin;
    width: iconSize;
    height: iconSize;
    vertical-align: middle;
}

.navPrimary span {
    display: inline-block;
    vertical-align: middle;
}

@media (min-width: mdBreak) {
    .navPrimary {
        flex-direction: row;
        align-items: center;
        width: unset;
        justify-content: flex-end;
        margin: 0 0 0 calc(3 * unit);
        flex-grow: 0;
    }
    .navPrimary > a {
        padding: calc(3 * unit) 0;
        height: calc(6 * unit + 1em);
    }

    .navPrimary > a:not(:last-child) {
        margin-right: 8px;
    }
}
