@value colorPrimary from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";
@value transitionSpeed from "../../common/metrics.css";
@value unit from "../../common/metrics.css";

.inputMultiSelect {
  display: block;
  position: relative;
  width: fit-content;
  outline: none;
}

.inputText {
  background-color: white !important;
  cursor: pointer;
}

.inputText:hover {
  border-color: colorPrimary;
}

.inputText::after {
  position: absolute;
  content: "";
  top: 22px;
  right: 22px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid colorShuttleGrey;
  display: block;
}

.input {
  cursor: pointer;
  max-width: calc(100% - 20px);
}

/* Select List */

.selectList {
  position: absolute;
  z-index: 1001;
  background-color: white;
  top: 50px;
  left: 0;
  max-height: 25vh;
  overflow-y: auto;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
  color: colorViolet;
  opacity: 0;
  display: none;
}

.open .selectList {
  display: block;
  opacity: 1;
  transition: opacity transitionSpeed;
}

.option {
  display: block;
  margin-bottom: 4px;
  padding: 0;
}
