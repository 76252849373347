@value bold, mdBreak from "../../common/metrics.css";
@value colorBackgroundGrey, colorCatskillWhite, colorViolet, colorMystic, colorShuttleGrey from "../../common/colors/colors.css";

.itineraryTopBar {
  border-top: 1px solid colorCatskillWhite;
}

.itinerary {
  font-size: 12px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 8px 0;
  letter-spacing: -0.5px;
  font-weight: 500;
  color: colorViolet;
}

.totalItem {
  color: grey;
}

.totalItem {
}

.right {
  text-align: right;
}

.totalItem {
  border: 1px solid #E1E8ED;
  border-radius: 8px;
  padding: 16px;
}

.financialDetails {
  font-size: 12px;
  line-height: 1.5;
}

.label {
  color: grey;
  font-size: 12px;
  font-weight: 500;
}

.itineraryActions {
  align-items: center;
}

.balance {
    font-weight: bold;
}

.noData {
  font-size: 14px;
  text-align: center;
  align-items: center;
  margin: 3rem auto;
  font-weight: 500;
  line-height: 24px;
  color: colorShuttleGrey;
}

.bookingsSpinner {
  position: absolute !important;
  right: 50%;
  top: 30%;
  z-index: 10;
}

.mainContent.split {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bookingsList {
  display: flex;
  flex-direction: column;
}

.bookingsList>:not(:last-child) {
  margin-bottom: 16px;
}

.selectedSummaryTitle {
  font-size: 16px;
  letter-spacing: -0.5px;
  border: 1px solid colorMystic;
  background-color: white;
  border-radius: 8px;
  padding: 8px 12px;
  display: block;
  font-weight: 500;
  line-height: 24px;
  color: colorViolet;
}

.selectedItems {
  display: flex;
  flex-direction: column;
}

.selectedItems>:not(:last-child) {
  margin-bottom: 16px;
}

@keyframes showSelectionSummary {
  from { max-width: 0; }
  to   { max-width: 200px; }
}

.openableContainer {
  display: block;
  overflow: hidden;
  transition: max-height 500ms ease;
  max-height: 0;
}

.paymentPanel {
  background-color: white;
  border-radius: 8px;
  border: 1px solid colorMystic;
}

@media (min-width: mdBreak) {
  .mainContent.split {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 16px;
  }
}
