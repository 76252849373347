@value unit, mdBreak, smBreak, lgBreak from "../../../../../common/metrics.css";
@value (
colorPrimaryYellow,
colorPrimaryLightBlue,
colorPrimaryGrey,
colorViolet,
colorWhite,
colorSecondaryGreen,
colorPrimaryGreen,
colorRemy,
colorAmaranth,
colorPrimary,
colorLightGrey,
colorBlackSqueeze
) from "../../../../../common/colors/colors.css";
@value roomRateListingMaxWidth from "../../../../../widgets/room-rate-listing/room-rate-listing.css";

@value height: 260px;
@value hotelCardImageWidthSm: 120px;
@value hotelCardImageWidth: 260px;

.hotelListingCard {
  position: relative;
}

.hotelCoverImage,
.noHotelCoverImage {
  width: hotelCardImageWidthSm;
  min-width: hotelCardImageWidthSm;
  height: 100%;
  border-top-left-radius: unit;
  border-bottom-left-radius: unit;
  cursor: pointer;
  position: relative;
}

.noHotelCoverImage {
  justify-content: center;
  align-items: center;
  background-color: colorBlackSqueeze;
}

.cardBody {
  display: grid;
  row-gap: 12px;
  column-gap: 12px;
  grid-template-columns: hotelCardImageWidthSm auto;
  grid-template-rows: auto auto;
  height: 100%;
  box-shadow: 0 4px 12px rgba(41, 43, 50, 0.08);
  border-radius: 8px;
  background-color: white;
}

.cardBody>.noHotelCoverImage,
.cardBody>.hotelCoverImage {
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}

.cardBody>.title {
  grid-column: 2;
  grid-row: 1;
}

.cardBody>.description {
  grid-column: 2;
  grid-row: 2;
}

.title {
  padding-top: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}

.addressSection {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  color: colorLightGrey;
  font-size: 12px;
  line-height: 16px;
  font-family: "TT Norms";
  font-weight: 700;
  font-style: normal;
}

.addressSection span {
  color: colorPrimaryGrey;
}

.addressSection .hotelAddress {
  display: none;
}

.addressSection svg {
  width: 14px;
  height: 14px;
}

.addressSection .clickable {
  color: colorPrimary;
  cursor: pointer;
}

.addressSection .clickable:hover {
  text-decoration: underline;
}

.hotelName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
}

.hotelName:hover {
  color: colorPrimary;
}

.marker {
  fill: colorPrimaryGrey;
  margin-right: unit;
}

.hotelPropertyTypeLabel {
  background-color: colorViolet;
  border-radius: 4px;
  padding: 0 calc(2 * unit);
  width: max-content;
  height: 24px;
  color: colorWhite;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: absolute;
  left: calc(-1 * unit);
  top: calc(2 * unit);
  z-index: 1;
}

.ratingSection {
  margin-top: unit;
}

.tripAdvisor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  line-height: 25px;
  font-size: 1rem;
  font-family: "TT Norms";
  font-weight: 500;
  font-style: normal;
}

.tripAdvisorIcon {
  margin-right: unit;
  fill: colorPrimaryGrey;
}

.tripAdvisorRating {
  font-family: "TT Norms";
  font-weight: 500;
  font-style: bold;
  color: colorViolet;
  margin-right: unit;
}

.addInfo {
  display: none;
  flex-direction: column;
  flex-grow: 1;
  font-size: 0.8rem;
  font-family: "TT Norms";
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  margin-top: calc(2.5 * unit);
  overflow: hidden;
}

.promotionSection {
  display: flex;
  min-height: 18px;
  align-self: flex-end;
}

.starIcon {
  fill: colorPrimaryYellow;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  padding-top: 0;
  padding-right: 12px;
  padding-bottom: 12px;
}

.lowestAveragePriceSection {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-top: calc(1 * unit);
}

.lowestAveragePriceLabel {
  font-size: 12px;
  text-align: right;
  color: colorPrimaryLightBlue;
  margin-top: calc(0.5 * unit);
  font-weight: 500;
  white-space: nowrap;
}

.lowestAveragePriceNumber {
  font-family: "TT Norms";
  font-weight: 700;
  font-style: bold;
  font-size: 32px;
  margin-right: calc(0.5 * unit);
  color: colorViolet;
}

.totalPriceSection {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  margin-top: calc(1 * unit);
  margin-bottom: calc(2 * unit);
}

.totalPriceSection :not(.nightsAndPax) {
  display: none;
}

.totalPriceLabel, .nightsAndPax {
  font-size: 12px;
  text-align: right;
  color: colorPrimaryGrey;
  font-weight: 500;
}

.totalPriceLabel {
  margin-top: calc(0.25 * unit);
}

.buttonsMobile {
  display: flex;
  flex-wrap: wrap;
  gap: unit;
}

.buttonsMobile>* {
  width: 100%;
}

.buttons {
  display: none;
}

.nightsAndPaxSkeleton {
  align-self: flex-end;
  margin-bottom: 4px;
}

.nightsAndPaxSkeleton,
.totalSkeleton,
.rateNoteSkeleton {
  width: 64px;
}

@media (min-width: smBreak) {
  .hotelListingCard {
    min-height: height;
    height: height;
  }

  .cardBody {
    display: flex;
  }

  .hotelName {
    font-size: 20px;
    line-height: 32px;
  }

  .buttons:not(:empty) {
    min-width: 200px;
  }

  .buttons {
    display: flex;
    gap: unit;
    flex-wrap: nowrap;
    justify-content: flex-end;
    max-height: 50px;
  }

  .buttons>* {
    width: auto;
  }
  
  .buttonsMobile {
    display: none;
  }
}

@media (min-width: mdBreak) {
  .hotelPropertyTypeLabel {
    left: calc(-2 * unit);
  }

  .cardBody {
  }

  .title {
    padding: calc(3 * unit);
  }
  
  .description {
    padding-top: calc(3 * unit);
    padding-right: calc(3 * unit);
    padding-bottom: calc(3 * unit);
    justify-content: space-between;
  }

  .addInfo {
    display: flex;
  }

  .totalPriceSection :not(.nightsAndPax) {
    display: flex;
  }

  .noHotelCoverImage,
  .hotelCoverImage {
    width: hotelCardImageWidth;
  }
}

@media (min-width: lgBreak) { 
  .addInfo {
    font-size: 1rem;
  }

  .addressSection .hotelAddress {
    display: inline;
  }

  .noHotelCoverImage,
  .hotelCoverImage {
    min-width: hotelCardImageWidth;
  }
}
