@value mdBreak from '../../../common/metrics.css';

.line {
    margin-bottom: 24px;
    width: 100%;
}

.phoneNumberLine {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    align-items: flex-end;
}

.phoneNumberLine>:last-child {
    margin-bottom: 0;
}

.saveButton {
    height: 40px;
    width: 100%;
}

.countryFlag {
    max-height: 10px;
}

@media (min-width: mdBreak) {
    .line {
        width: 416px;
    }

    .phoneNumberLine {
        display: flex;
        align-items: flex-end;
        gap: 8px;
    }

    .phoneNumberLine>:first-child {
        width: 145px;
    }

    .phoneNumberLine>:last-child {
        width: 264px;
    }

    .saveButton {
        width: 62px;
        margin-top: 12px;
    }
}
