@value unit from "../../common/metrics.css";
@value maxContentWidth from "../../common/metrics.css";
@value colorViolet from "../../common/colors/colors.css";

@value footerMinHeight: 110px;

body {
    width: 100%;
}

.pageSection {
    padding: 0 calc(2 * unit);
}

.pageSectionContainer {
    margin: 0 auto;
    max-width: maxContentWidth;
    min-height: inherit;
}

.header {
    background-color: colorViolet;
}

.footer {
    min-height: footerMinHeight;
    background-color: colorViolet;
    flex-shrink: 0;
}
