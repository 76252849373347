@value colorLightOrange, colorPolar, colorRemy, colorBlackSqueeze, colorSerenade from "../../common/colors/colors.css";
@value mdBreak, lgBreak from "../../common/metrics.css";

@value bookingSummaryBoxWidth: 308px;
@value quickSearchHeight: 112px;

.dashboard {
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: quickSearchHeight auto auto;
  row-gap: 32px;
}

.bookingsSummary {
  display: flex;
  flex-direction: column;
}

.shadowedBox {
  background: white;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
  border-radius: 8px;
  display: flex;
  padding: 16px;
}

.statusBookingNumberBox:not(:first-child) {
  margin-top: 16px;
}

.boxHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.boxHeadingWrapper {
  display: flex;
  flex-direction: row;
}

.boxBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.totalBookingNumberBox {
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.totalBookingNumber {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
  letter-spacing: -2px;
  align-self: flex-end;
}

.statusBookingNumberBox {
  height: 56px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.statusBookingNumberBox .icon {
  margin-right: 18px;
  height: 20px;
}

.statusBookingNumberBox .icon>svg {
  width: 20px;
  height: 20px;
}

.quoteBookingNumberBox {
  background: colorSerenade;
  color: #fb8700;
}

.confirmedBookingNumberBox {
  background: colorPolar;
  color: #00c48c;
}

.pendingBookingNumberBox {
  background: colorLightOrange;
  color: #fdbc22;
}

.cancelledBookingNumberBox {
  background: colorRemy;
  color: #f2335c;
}

.unavailableBookingNumberBox {
  background: #e4e4e4;
  color: #444444;
}

.travelledBookingNumberBox {
  background: #def2ff;
  color: #55669d;
}

.latestBookings {
  align-self: flex-start;
  padding: 0;
  width: 100%;
}

.quickSearch.shadowedBox {
  margin-top: 0;
}

@media (min-width: mdBreak) {
  .dashboard {
    grid-template-rows: quickSearchHeight auto;
    grid-template-columns: bookingSummaryBoxWidth auto;
    column-gap: 32px;
  }
  
  .bookingsSummary {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1;
  }

  .quickSearch {
    grid-row: 1;
    grid-column: 2;
  }

  .latestBookings {
    grid-row: 2;
    grid-column: 2;
  }
}

@media (min-width: lgBreak) {
  .dashboard {
    grid-template-columns: bookingSummaryBoxWidth 2fr 1fr;
    grid-template-rows: quickSearchHeight auto;
  }

  .bookingsSummary {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 1;
  }

  .quickSearch {
    grid-row: 1;
    grid-column: 3;
  }

  .latestBookings {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
  }
}
