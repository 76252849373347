/* @todo Some of the values in this component need to be de-magiced,
  delayed pending a broader solution to scrolling */
@value colorMystic, colorPrimary, colorPictonBlue from "../../../../common/colors/colors.css";

.filter {
  font-size: 14px;
  border-bottom: 1px solid colorMystic;
  padding-top: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
}

.inputText {
  width: 100%;
}

.optionList {
  position: relative;
  margin: 16px 0 0 0;
  padding-right: 4px;
}

.cta {
  padding: 4px 0;
  background-color: transparent;
}

.cta:hover:not(:disabled) {
  box-shadow: none;
  text-decoration: underline;
}

.cta:active:not(:disabled) {
  background-color: transparent;
}

.optionListContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: -4px;
  overflow-y: hidden;
  align-items: flex-start;
}

.optionListContainer {
  overflow-y: auto;
  padding: 0 0 12px;
}

.optionListContainer > label {
  margin: 4px 4px 8px 4px;
}

.optionListContainer > label:last-child {
  margin-bottom: 4px;
}

/* Nicer Scrollbar */

.optionListContainer {
  scrollbar-color: rgba(0,0,0,0.3) tranparent;
}

.optionListContainer::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.optionListContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

.optionListContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:rgba(0,0,0,0.3);
}

.optionListContainer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.optionListContainer::-webkit-scrollbar-corner {
  width: 16px;
}
