@value colorBlackSqueeze, colorViolet, colorPrimary from "../../../../common/colors/colors.css";
@value unit, lightShadow, mdBreak from "../../../../common/metrics.css";

.root {
    width: unset;
    height: unset;
    padding: 24px 32px 16px;
}

.content {
    background-color: white;
    border-radius: 8px;
    position: relative;
    max-width: 695px;
}

.title {
    margin: 0 0 40px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.phoneRow {
    display: flex;
    align-items: flex-end;
    margin-bottom: calc(4 * unit);
}

.phoneRow>:first-child {
    margin-right: unit;
    width: 145px;
}

.phoneRow>:last-child {
    margin-bottom: 0;
    flex-grow: 1;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.actions>* {
    height: 48px;
    border-radius: calc(1.5 * unit);
}

.actions>:first-child {
    width: 72px;
}

.actions>:last-child {
    margin-left: 16px;
    width: 97px;
}

.countryFlag {
    max-height: 10px;
}

.modalFooter {
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    box-shadow: 0 4px 8px rgba(41, 43, 50, 0.08);
    margin-bottom: 32px;
    border-radius: 12px;
    align-items: stretch;
    background-color: white;
}

.tabContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.agencyModal {
    width: 80%;
    height: 80%;
    max-width: 850px;
    max-height: 850px;
    padding: 12px 24px;
}

.agencyModalTitle {
    font-size: 20px;
    color: colorViolet;
    font-weight: 500;
    padding: 15px 0 25px;
}

.agencyModalInner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.saveBtn {
    margin-left: 10px;
}

@media (min-width: mdBreak) {
    .tabs {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 40px;
        min-height: 40px;
    }

    .agencyModal {
        padding: 24px 32px 16px;
    }

    .agencyModalTitle {
        font-size: 24px;
    }
}
