@value colorPrimaryGrey, colorViolet, colorAqua, colorPrimary, colorMystic from "../../../common/colors/colors.css";
@value mdBreak, smBreak from "../../../common/metrics.css";

.root {
    display: flex;
    flex-direction: column;
    color: colorViolet;
    margin-bottom: 24px;
}

.root h2 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.75px;
    font-weight: 500;
    margin-bottom: 16px;
}

.categories {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.category>:first-child {
    margin-bottom: 8px;
}

.category ul {
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 4px 12px rgba(41, 43, 50, 0.08);
}

.category ul li {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    cursor: pointer;
    letter-spacing: -0.25px;
    transition: all 0.2s;
}

.category ul li.selected {
    background-color: colorAqua;
    color: colorPrimary;
}

.category ul li:hover {
    background-color: colorMystic;
}

.category ul li:active {
    background-color: colorPrimary;
    color: white;
}

@media (min-width: smBreak) {
    .categories {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
    }
}

@media (min-width: mdBreak) {
    .root {
        margin-bottom: 48px;
    }
}
