@value (
    colorViolet,
    colorPrimary,
    colorSecondary,
    colorFoam,
    colorAqua
) from "../../../../common/colors/colors.css";
@value mdBreak from "../../../../common/metrics.css";

.detailsButton {
    background-color: colorSecondary;
    color: colorPrimary;
    height: 24px;
    width: 44px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.detailsButton:hover {
    background-color: colorFoam;
}

.list {
    box-shadow: 0 4px 8px rgba(41, 43, 50, 0.08);
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 25px;
    width: 192px;
    background-color: white;
    z-index: 2;
}

.list>li {
    font-family: "TT Norms", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: colorViolet;
    cursor: pointer;
    height: 40px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.list>li:hover {
    background-color: colorAqua;
}

@media (min-width: mdBreak) {
    .list {
        right: 0;
        left: unset;
    }
}
