@value colorPrimary, colorAqua, colorViolet from '../../../../common/colors/colors.css';

@value unit, mdBreak from "../../../../common/metrics.css";

.container {
    height: 500px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
}

.container.fullscreen {
    height: 50vh;
    width: 100%;
}

.image {
    max-width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    pointer-events: none;
}

.navButton {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: rgba(8, 8, 55, 0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.navButton svg {
    width: 14px;
    height: 14px;
}

.navButton path {
    fill: white;
}

.navButton:hover {
    box-shadow: 0 4px 12px rgba(41, 43, 50, 0.16);
}

.navButton:active {
    background-color: rgba(8, 8, 55);
}

.navButton.left {
    left: 0;
}

.navButton.right {
    right: 0;
}

.navButton:disabled {
    background-color: rgba(255, 255, 255, 0.25);
}

.count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 112px;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    background: rgba(8, 8, 55, 0.6);
    border-radius: 12px;
    padding: 0 12px;
}

.count>span {
    font-weight: 300;
    letter-spacing: -0.75px;
}

.countNumberShaded {
    color: rgba(255, 255, 255, 0.25);
}

.countNumberBright {
    color: white;
}

.countLine {
    width: 48px;
    height: 2px;
    border-radius: 2px;
}

.clickable {
    cursor: pointer;
}

@media (min-width: mdBreak) {
    .container.fullscreen {
        height: 90vh;
    }
    
    .image {
        pointer-events: auto;
    }
}
