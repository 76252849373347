@value unit from "../../common/metrics.css";
@value colorPrimaryGrey from "../../common/colors/colors.css";
@value colorViolet from "../../common/colors/colors.css";

/* Price Component */

.price {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  line-height: 1;
}

.amount {
  display: flex;
  font-weight: bold;
  margin-right: calc(0.5 * unit);
  color: colorViolet;
  position: relative;
}

.large .amount {
  font-size: 32px;
}

.medium .amount {
  font-size: 24px;
}

.small .amount {
  font-size: 20px;
}

.small .currency {
  font-size: 9px;
  align-self: center;
}

.small .currencySign {
  font-size: 12px;
  left: -12px;
}

.currency {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: colorPrimaryGrey;
  letter-spacing: 2px;
}

.amountSkeleton {
  width: calc(0.4 * 6em);
}

.currencySkeleton {
  width: calc(0.6 * 3em);
}

.currencySign {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  color: colorPrimaryGrey;
  line-height: 24px;
  right: calc(100% + 6px);
  top: -2px;
}
