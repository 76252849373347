@value colorSky from "../../../common/colors/colors.css";

.mapModal {
  height: 90vh !important;
  width: 90vw !important;
  padding: 0 !important;
}

.mapModalContent {
  width: 100%;
  height: 100%;
}

.mapModalContent button[class*="gm-ui-hover-effect"] {
  display: none !important;
}

.mapModalContent div[class*="gm-style-iw-d"] {
  padding: 0 !important;
  overflow: auto !important;
  height: auto;
  width: auto;
}

.mapModalContent div[class*="gm-style-iw"] {
  padding: 0 !important;
  outline: none !important;
  border: none !important;
}

.mapModalContent div[class*="gm-style"]::after {
  display: none !important;
}

.mapModalContent button[class*="gm-fullscreen-control"] {
  display: none;
}

.mapSideSection {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 16px rgba(44, 44, 44, 0.45);
  background-color: white;
  padding: 16px;
  border-bottom-right-radius: 8px;
}

.mapSideSection .hotelName {
  font-size: 20px;
  line-height: 1.5em;
}

.mapSideSection .hotelAddress {
  font-size: 14px;
  line-height: 2em;
}

.mapSideSection .hotelPriceSection span[class*="amount"] {
  font-size: 20px;
  font-weight: 500;
}

.lowestAverageNightlyRate {
  color: colorSky;
  font-size: 12px;
  width: 100%;
  text-align: right;
}

.showMoreButton {
  position: absolute;
  top: 30%;
  left: 16px;
  opacity: 0.75;
  transition: opacity 0.5s;
  font-size: 20px;
}

.showMoreButton:hover {
  opacity: 1;
}

.showMoreButton path {
  fill: white;
}

.showMoreButton svg {
  height: 16px;
  width: 16px;
  display: block;
}

.eyeIcon {
  height: 16px;
  width: 16px;
}
