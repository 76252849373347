@value unit, mdBreak from "../../../../common/metrics.css";
@value colorLightGrey, colorViolet from "../../../../common/colors/colors.css";

.cardsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 calc(unit * 2);
}

.latestBookingCard {
    width: 100%;
    padding: calc(unit * 2) 0;
    display: flex;
    justify-content: space-between;
}

.latestBookingCard:hover {
    background-color: rgba(197, 208, 216, 0.3);
    text-decoration: none;
    color: colorViolet;
}

.latestBookingCard>:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.latestBookingCard:not(:last-child) {
    border-bottom: 1px solid colorLightGrey;
}

@media (min-width: mdBreak) {
    .cardsContainer {
        display: none;
    }
}
