@value colorPrimaryGrey from "../../common/colors/colors.css";

.agentusLabel {
    display: block;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 2px;
    font-weight: 700;
    color: colorPrimaryGrey;
    text-transform: uppercase;
    margin-bottom: 8px;
}
