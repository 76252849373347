@value colorViolet, colorCatskillWhite, colorShuttleGrey from "../../../common/colors/colors.css";

.comments {
  margin-bottom: 40px;
}

.commentsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.commentsTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.75px;
  color: colorViolet;
}

.addButton svg {
  margin-right: 5px;
}

.commentsTable {
  table-layout: fixed;
  width: 100%;
  font-size: 12px;
  line-height: 2em;
  font-weight: 500;
  text-align: left;
}

.commentsTable th {
  font-weight: 700;
  background: colorCatskillWhite;
}

.commentsTable .textColumn {
  width: 50%;
}

.commentsTable th,
.commentsTable td {
  padding: 8px 16px;

}

.commentsTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commentsTable tbody tr {
  cursor: pointer;
}
.commentsTable tbody tr:hover {
  background: #F1F6F9;
}

.emptyData {
  width: 100%;
  padding: 16px;
  text-align: center;
  font-size: 12px;
  color: colorShuttleGrey;
}
