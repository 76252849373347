@value borderRadius, borderWidth, unit, transitionSpeed, medium from "../../common/metrics.css";
@value colorPrimary, colorShuttleGrey, colorHawkesBlue, colorPrimaryGrey, colorPrimaryYellow, colorCatskillWhite, colorMystic from "../../common/colors/colors.css";

@value proxyWidth: 24px;
@value proxyHeight: 24px;
@value borderRadius: 4px;
@value borderWidth: borderWidth;
@value transitionDuration: transitionSpeed;
@value borderColor: colorShuttleGrey;
@value backgroundColor: colorPrimary;
@value hoverBackgroundColor: colorHawkesBlue;
@value proxyGap: unit;
@value outlineWidth: 2px;
/* This is also used to calculate text top padding */
@value lineHeight: 24px;
@value proxyWidthSmall: 16px;
@value proxyHeightSmall: 16px;
@value proxyWidthLarge: 30px;
@value proxyHeightLarge: 30px;

.checkbox {
    outline: none;
    display: flex;
}

.container {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-weight: medium;
}

.checkbox > * {
    vertical-align: middle;
}

.checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
    /* display: none; */
    width: unset;
}

.checkboxProxy {
    display: inline-block;
    width: proxyWidth;
    height: proxyHeight;
    border: borderWidth solid colorMystic;
    border-radius: borderRadius;
    background-color: white;
    color: white;
    font-size: 0;
    transition: background-color transitionDuration;
}

.small .container {
    min-height: proxyHeightSmall;
    line-height: proxyHeightSmall;
}
.small .container .checkboxProxy {
    width: proxyWidthSmall;
    height: proxyHeightSmall;
}
.small .container .text {
    line-height: proxyHeightSmall;
}

.large .container {
    min-height: proxyWidthLarge;
}
.large .container .checkboxProxy {
    width: proxyWidthLarge;
    height: proxyHeightLarge;
}
.large .container .text {
    line-height: proxyHeightLarge;
}

.checkbox:hover .checkboxProxy {
    border-color: colorPrimary;
    background-color: hoverBackgroundColor;
}

.checkboxProxy > div {
    display: none;
    width: 100%;
    height: 100%;
}

.checkbox.shim .checkboxProxy {
    margin-right: unit;
}

.checkbox input:checked + .checkboxProxy,
.checkbox input:indeterminate + .checkboxProxy {
    border-color: colorPrimary;
    background-color: backgroundColor;
    transition: background-color transitionDuration;
}

.checkbox input:checked + .checkboxProxy div.tickIcon {
    display: block;
}

.checkbox input:indeterminate + .checkboxProxy div.dashIcon {
    display: block;
}

.checkbox .text {
    display: inline-block;
    max-width: calc(100% - proxyWidth - proxyGap);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: calc(proxyHeight - lineHeight);
    padding-right: 2px;
}

/* Variant: multiline */

.checkbox.multiline .checkboxProxy {
    vertical-align: top;
}

.checkbox.multiline .text {
    white-space: normal;
}

/* Variant: Disabled */

.checkbox input:disabled + .checkboxProxy {
    background-color: colorCatskillWhite;
    border-color: colorPrimaryGrey;
    color: colorPrimaryGrey;
}
