@value smBreak, mdBreak from '../../common/metrics.css';

.inputDate {
  position: relative;
}

:global .react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

:global .react-datepicker-popper {
  z-index: 2 !important;
}

.calendarWrapper {
  position: absolute;
  top: calc(100% + 12px);
  visibility: hidden;
  z-index: 2;
}

.calendarWrapper.opened {
  visibility: visible;
}

@media (min-width: smBreak) {
  .calendarWrapper {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: mdBreak) {
  .calendarWrapper.alignLeft {
    left: 0;
    transform: none;
    right: unset;
  }

  .calendarWrapper.alignRight {
    left: unset;
    transform: none;
    right: 0;
  }
}
