@value colorPrimaryYellow, colorViolet, colorPrimary, colorBlackSqueeze from "../../../common/colors/colors.css";
@value mdBreak, unit, transitionSpeed from "../../../common/metrics.css";

@value maxClipWrapWidth: 304px;

.filterPanelContainer {
  display: flex;
  transition: max-width transitionSpeed;
}

.filterPanelContainer.collapsed {
  max-width: 32px !important;
}

.clipWrap {
  overflow: clip;
  height: 100%;
  width: 100%;
}

.filterPanel {
  transition: all 100ms;
  overflow: hidden;
  flex-grow: 0;
  flex-basis: auto;
  opacity: 1;
}

.filterPanel::-webkit-scrollbar {
  width: unit;
}

.filterPanelContainer.collapsed .filterPanel {
  opacity: 0;
}

.head {
  display: flex;
  gap: 16px;
}

.smContainer {
  height: 90dvh;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.smContainer>div {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  scrollbar-gutter: stable;
  overflow-y: auto;
}

.smContainer>h3 {
  font-size: 24px;
  margin-bottom: 12px;
}

.mdContainer {
  display: none;
  flex-direction: column;
  gap: 16px;
}

.otherFiltersButton {
  display: block;
}

.starIcon {
  fill: colorPrimaryYellow;
}

.collapseButton {
  display: none;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  min-width: 32px;
  transition: flex-basis transitionSpeed;
  flex-basis: 0;
  border-radius: unit;
  margin-left: 4px;
}

.filterPanelContainer.collapsed .collapseButton {
  flex-basis: 100%;
}

.collapseButton:hover {
  background-color: colorBlackSqueeze;
}

.collapseButton:hover path {
  fill: colorViolet;
}

.collapseButton:active path {
  fill: colorPrimary;
}

.collapseButton svg {
  transform: rotate(180deg);
  transition: all 200ms;
}

.filterPanelContainer.collapsed .collapseButton svg {
  transform: none;
}

@media (min-width: mdBreak) {
  .filterPanelContainer {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .filterPanel.sticky {
    top: 24px;
    position: sticky;
    overscroll-behavior: contain;
  }

  .head {
    margin-bottom: calc(unit * 2);
  }

  .smContainer,
  .otherFiltersButton {
    display: none;
  }

  .mdContainer {
    display: flex;
  }

  .clipWrap {
    max-width: maxClipWrapWidth;
  }
  
  .collapseButton {
    display: flex;
  }
}
