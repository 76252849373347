@value
    (colorSecondary,
    colorViolet,
    colorShuttleGrey,
    colorPolar,
    colorCaribbeanGreen,
    colorBackgroundGrey,
    colorAmaranth,
    colorRemy)
from "../../../../../common/colors/colors.css";
@value unit, mdBreak, smBreak from "../../../../../common/metrics.css";

.cancellationModal {
    width: 70dvw;
}

.content {
    flex-grow: 1;
}

.content section {
    margin-bottom: 60px;
}

.title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1px;
    font-weight: 500;
    margin-bottom: 20px;
}

.cancellationModal p {
    font-weight: 500;
    color: black;
    font-size: 12px;
    letter-spacing: -0.5px;
    line-height: 24px;
}

.sectionTitle {
    color: colorShuttleGrey;
}

.descriptionContainer {
    background-color: colorSecondary;
    border-radius: unit;
    display: flex;
    align-items: flex-start;
    padding: 24px 24px 24px 27px;
    max-height: 150px;
    overflow: hidden;
    margin-bottom: 40px;
}

.descriptionContainer > svg {
    min-width: 28px;
    margin-right: 11px;
}

.descriptionContainer > svg > path {
    fill: #0FA6ED;
}

.description {
    overflow: auto;
    height: 100px;
}
.description > ul {
    list-style: inside;
}

.timelinePoints {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.timelinePoint {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timelinePoint:first-child {
    align-items: flex-start;
}

.timelinePoint:last-child {
    align-items: flex-end;
}

.timelinePoint>:first-child {
    color: colorViolet;
    font-size: 14px;
    letter-spacing: -0.25px;
    font-weight: 600;
}

.timelinePoint>:last-child {
    color: colorShuttleGrey;
    font-size: 10px;
    font-weight: 600;
}

.getByDate {
    transform: translateX(-10px);
}

.timeline {
    display: flex;
}

.text {
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.timeline>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 24px;
    position: relative;
}

.freeCancellation {
    background-color: colorPolar;
    color: colorCaribbeanGreen;
}

.freeCancellation::before, .freeCancellation::after, .partiallyRefundable::after, .nonRefundable::before, .nonRefundable::after {
    position: absolute;
    content: "";
    height: 30px;
    width: 2px;
    top: -3px;
}

.freeCancellation::before, .nonRefundable::before {
    left: 0;
    background-color: colorShuttleGrey;
}

.freeCancellation::after {
    right: 0;
    background-color: colorAmaranth;
}

.partiallyRefundable {
    background-color: colorBackgroundGrey;
    color: colorShuttleGrey;
}

.partiallyRefundable::after, .nonRefundable::after {
    right: 0;
    background-color: colorShuttleGrey;
}

.nonRefundable {
    position: relative;
    background-color: colorRemy;
    color: colorAmaranth;
    margin-bottom: 24px;
    text-align: center;
}

.policyRow {
    display: flex;
    justify-content: space-between;
}

.closeButton {
    align-self: flex-end;
}

@media (min-width: smBreak) {
    .cancellationModal {
        width: 50dvw;
    }

    .title {
        margin-bottom: 40px;
    }

    .timelinePoint>:first-child {
        font-size: 14px;
    }

    .timelinePoint>:last-child {
        font-size: 12px;
    }

    .text {
        font-size: 9px;
    }

    .cancellationModal p {
        font-size: 16px;
    }
}

@media (min-width: mdBreak) {
    .cancellationModal {
        width: 695px;
        max-height: 772px;
    }
}
