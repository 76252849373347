@value unit from "../../common/metrics.css";
@value smBreak from "../../common/metrics.css";
@value maxContentWidth from "../../common/metrics.css";
@value colorBackgroundGrey from "../../common/colors/colors.css";

.pageSection {
    padding: 0 calc(2 * unit);
}

@media (min-width: smBreak) {
    .pageSection {
        padding: 0 calc(3 * unit);
    }
}

.pageSectionContainer {
    margin: 0 auto;
    max-width: maxContentWidth;
}

.mainPageSection {
    flex: 1 0 auto;
    background-color: colorBackgroundGrey;
}

.pad {
    padding: calc(2 * unit) 0;
}
