@value colorViolet from "../../common/colors/colors.css";
@value smBreak from "../../common/metrics.css";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(8, 8, 55, 0.95);
  z-index: 10;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
}

.modalMain {
  background: white;
  padding: 24px 32px;
  border-radius: 10px;
  max-width: calc(100dvw - 48px);
}

.modal .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: colorViolet;
  margin-bottom: 30px;
}

.modalMain.contentCorner {
  position: relative;
}

.open {
  opacity: 1;
  visibility: visible;
}

.close {
  opacity: 0;
  visibility: hidden;
}

.modal button.closeBtn {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: none;
  background: white;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
  position: absolute;
  align-self: flex-end;
}

.closeBtn.contentCorner {
  right: 0;
  top: 0;
  margin-top: -20px;
  margin-right: -20px;
}

.closeBtn.viewportCorner {
  top: 16px;
  right: 16px;
}

@media (min-width: smBreak) {
  .modalMain {
    
  }
}
