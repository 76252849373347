@value colorBlackSqueeze, colorViolet, colorShuttleGrey from "../../../common/colors/colors.css";

.latestBookings {
    flex-direction: column;
    height: fit-content;
    width: 100%;
    padding: 16px 0;
    color: colorViolet;
    min-height: 100px;
}

.title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-weight: 500;
    margin-bottom: 24px;
    margin-left: 16px;
}
