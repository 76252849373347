@value borderRadius, borderWidth, unit, transitionSpeed from "../../common/metrics.css";
@value (
colorPrimary,
colorPrimaryGrey,
colorPrimaryYellow,
colorCatskillWhite,
colorMystic,
colorLoblolly) from "../../common/colors/colors.css";

@value radioProxyWidth: 24px;
@value radioProxyHeight: 24px;
@value radioBorderRadius: borderRadius;
@value radioBorderWidth: borderWidth;
@value radioTransitionDuration: transitionSpeed;
@value radioColor: colorPrimaryGrey;
@value radioCheckedBorderWidth: 8px;
@value radioProxyGap: unit;
@value radioOutlineWidth: 2px;
@value radioWidthSmall: 16px;
@value radioHeightSmall: 16px;
@value radioCheckedBorderWidthSmall: 5px;
@value radioWidthLarge: 30px;
@value radioHeightLarge: 30px;
@value radioCheckedBorderWidthLarge: 9px;

.radio {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    min-height: radioProxyHeight;
    white-space: nowrap;
    cursor: pointer;
}

/* This creates an outline when focused. */
.radio:focus {
    outline: none;
}
.radio:focus:not(:has(input:disabled)) .radioProxy {
    outline: 1px solid colorPrimary;
    outline-offset: 2px;
}



.radio > * {
    vertical-align: middle;
}

.radio input[type=radio] {
    display: none;
    width: unset;
}

.radioProxy {
    display: inline-block;
    width: radioProxyWidth;
    height: radioProxyHeight;
    border: radioBorderWidth solid colorMystic;
    border-radius: radioProxyWidth;
    background-color: white;
    color: white;
    font-size: 0;
    transition: background-color radioTransitionDuration;
}

.radio.small {
    min-height: radioHeightSmall;
}
.radio.small .radioProxy {
    width: radioWidthSmall;
    height: radioHeightSmall;
    min-width: radioWidthSmall;
}

.radio.large {
    min-height: radioHeightLarge;
}
.radio.large .radioProxy {
    width: radioWidthLarge;
    height: radioHeightLarge;
    min-width: radioWidthLarge;
}

.radio:hover .radioProxy {
    border-color: colorPrimary;
}

.radioProxy > div {
    display: none;
    width: 100%;
    height: 100%;
}

.radio.shim .radioProxy {
    margin-right: unit;
}

.radio input:checked + .radioProxy {
    border-color: colorPrimary;
    border-width: radioCheckedBorderWidth;
    transition: border-width radioTransitionDuration;
}

.radio.small input:checked + .radioProxy {
    border-width: radioCheckedBorderWidthSmall;
}
.radio.large input:checked + .radioProxy {
    border-width: radioCheckedBorderWidthLarge;
}

.radio input:checked + .radioProxy div.tickIcon {
    display: block;
}

.text {
    display: inline-block;
    max-width: calc(100% - radioProxyWidth - radioProxyGap);
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Variant: multiline */

.radio.multiline .radioProxy {
    align-self: flex-start;
}

.radio.multiline .text {
    white-space: normal;
}

/* Variant: Disabled */

.radio input:disabled + .radioProxy {
    background-color: colorCatskillWhite;
    border-color: colorLoblolly;
}

.radio:has(input:disabled) .text {
    color: colorLoblolly;
}

.radio:has(input:disabled) {
    pointer-events: none;
}
