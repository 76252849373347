@value unit, smBreak from "../../common/metrics.css";
@value borderRadius from "../../common/metrics.css";
@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorBlack from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";
@value colorLoblolly from "../../common/colors/colors.css";

@value headerHeight: 56px;

@value scrollBarWidth: 4px;

.roomRateListing {
  margin-top: calc(2 * unit);
  width: 100%;
  box-shadow: 0 4px 12px rgba(41, 43, 50, 0.08);
  overflow-y: auto;
  scrollbar-width: thin;
  border-radius: borderRadius;
}

.roomRateListing::-webkit-scrollbar {
  width: scrollBarWidth;
}

.collapsible {
  transition: max-height 500ms;
}

.roomRateListing.fullWidth {
  max-width: none;
}

.collapsed {
  max-height: 0 !important;
}

/* Layout Grid and Body of Height transition */
.gridHead,
.gridBody {
  padding: calc(2 * unit);
}

.gridHead {
  display: none;
  grid-template-columns: 120px 2fr 1fr 3fr 3fr 1fr;
  column-gap: calc(2 * unit);
  padding-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.gridBody {
  display: flex;
  flex-direction: column;
  background-color: white;
  row-gap: calc(2 * unit);
}

/* General Header Item */
.headerItem {
  display: flex;
  font-weight: bold;
  height: headerHeight;
  margin-top: calc(-2 * unit);
  margin-left: calc(-2 * unit);
  margin-right: calc(-2 * unit);
  padding: 0 calc(2 * unit);
  background: colorCatskillWhite;
  align-items: center;
  position: relative;
}

/* Header Item Customisatins */

.headerRoomInfo {
  grid-column-start: 1;
  grid-column-end: 3;
}

.headerItemSleeps {
  text-align: center;
}

.headerItemRates {
  text-align: right;
  flex-grow: 1;
}

/* Divider */
.divider {
  grid-column-start: 1;
  grid-column-end: 7;
  margin: 0;
  width: 100%;
  background-color: colorLoblolly;
}

.divider:last-child {
  display: none;
}

.noPackagesMessage {
  grid-column-start: 1;
  grid-column-end: 6;
  text-align: center;
}

.boardFilter {
  background: none;
  border: 0;
  line-height: 1;
  margin-left: 5px;
}

.boardSelect {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.multiSelectInputText {
  /* to override a previous important rule */
  background-color: transparent !important;
  color: colorBlack;
}

.multiSelectInputText.withOverride {
  border-color: colorLoblolly;
}

.multiSelectInputText::after {
  border-top-color: colorBlack;
}

.multiSelectInputText input {
  /* to override a previous important rule */
  color: colorBlack;
  font-weight: bold;
}

.multiSelectInputText input::placeholder {
  font-weight: bold;
  color: colorBlack !important;
}

@media (min-width: smBreak) {
  .gridHead {
    display: grid;
  }

  .divider {
    background-color: colorCatskillWhite;
  }
}
