@value heavyShadow from '../../common/metrics.css';
@value colorShuttleGrey, colorViolet, colorMystic, colorPrimary from '../../common/colors/colors.css';

.passwordRecovery {
    height: 100vh;
    background-image: url('../../../assets/img/_pPHgeHz1uk.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100dvw - 24px);
    padding: 48px 32px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(41, 43, 50, 0.25);
}

.head {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.head > span {
    font-size: 16px;
    color: colorViolet;
    letter-spacing: -0.5px;
}

.backButton {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
    box-shadow: heavyShadow;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backButton:hover {
    background-color: colorMystic;
}

.backButton:active {
    background-color: colorPrimary;
}

.backButton:active path {
    fill: white;
}

.backButton svg {
    height: 14px;
    width: 14px;
}

.backButton path {
    fill: colorShuttleGrey;
}

.submitButton {
    align-self: flex-start;
    height: 40px;
    border-radius: 12px;
    width: 130px;
}

@media (min-width: 400px) {
    .content {
        max-width: 400px;
    }
}
