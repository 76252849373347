@value (
  colorBlackSqueeze,
  colorViolet,
  colorShuttleGrey,
  colorPrimaryYellow,
  colorPrimaryGrey,
  colorAmaranth,
  colorAqua
) from "../../../../../common/colors/colors.css";

@value smBreak, unit, borderRadius from "../../../../../common/metrics.css";

.hotelMapResult {
  font-family: 'TT Norms';
  background: white;
  box-shadow: 0 4px 12px rgba(41, 43, 50, 0.08);
  border-radius: unit;
  min-height: 96px;
  height: 96px;
  display: flex;
  flex-direction: row;
  margin-bottom: unit;
  cursor: pointer;
}

.hotelMapResult:hover {
  background-color: colorAqua;
}

.infoSection {
  padding: 13px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hotelCoverImage {
  display: flex;
  width: 96px;
  min-width: 96px;
  height: 96px;
  background-size: cover;
  border-top-left-radius: borderRadius;
  border-bottom-left-radius: borderRadius;
}

.hotelCoverImage svg {
  width: 20px;
}

.rating {
  height: 16px;
  margin: 0 0 3px;
}

.starIcon {
  fill: colorPrimaryYellow;
}

.noHotelCoverImage {
  display: flex;
  width: 96px;
  min-width: 96px;
  height: 96px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: colorBlackSqueeze;
}

.imageSlashIcon {
  width: 20px;
  fill: colorPrimaryGrey;
}

.hotelName {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  letter-spacing: -0.25px;
  font-feature-settings: 'liga' off;
  color: colorViolet;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.priceDetails {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-content: center;
  line-height: 12px;
}

.priceValue {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: colorViolet;
  margin-right: 4px;
}

.soldOut {
  color: colorAmaranth;
  text-transform: uppercase;
  font-size: 0.5625rem;
  font-weight: 700;
  letter-spacing: 2px;
}

.currency {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorShuttleGrey;
  align-content: center;
}

.amountSkeleton {
  width: calc(0.6 * 6em);
}

.currencySkeleton {
  width: calc(0.6 * 3em);
}

@media (min-width: smBreak) {
  .hotelMapResult {
    width: 308px;
  }
}
