@value colorPrimary, colorLoblolly from "../../../common/colors/colors.css";
@value mdBreak from "../../../common/metrics.css";

.breadcrumbs {
  display: flex;
  flex-grow: 1;
}

.breadcrumbs li {
  display: flex;
  align-items: center;
}

.breadcrumbs li svg {
  margin: 0 8px;
}

.breadcrumbs li path {
  fill: colorLoblolly;
}

.breadcrumbButton {
  background-color: transparent;
  color: colorLoblolly;
}

.breadcrumbButton:hover, .breadcrumbButton:focus {
  color: colorPrimary;
  cursor: pointer;
}

.breadcrumbs .current {
  color: colorPrimary;
  font-weight: bold;
  cursor: default;
}

@media (min-width: mdBreak) {
  .breadcrumbs {
    flex-grow: 0;
  }
}
