@value colorViolet from "../../common/colors/colors.css";

.image {
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image svg {
    display: block;
}

.medium svg {}

.large svg {
    scale: 2;
}

.invertedColor path {
    fill: colorViolet;
}
