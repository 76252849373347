.iconButton {

}

.iconButtonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.large .iconButtonContainer {
  gap: 12px;
}

.small .iconButtonContainer {
  gap: 6px;
}
