@value (
    colorMystic,
    colorLightOrange,
    colorLightningYellow,
    colorPictonBlue,
    colorViolet,
    colorShuttleGrey
) from "../../../common/colors/colors.css";

@value smBreak, mdBreak from "../../../common/metrics.css";

.bookingContainer {
    border: 1px solid colorMystic;
    border-radius: 8px;
    padding-left: 27px;
    position: relative;
    background-color: white;
    width: 100%;
    overflow: hidden;
}

.booking {
    background-color: white;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    flex-grow: 1;
    width: 100%;
}

.status {
    position: absolute;
    top: 0;
    left: 0;
    writing-mode: tb;
    height: 100%;
    width: 28px;
    transform: rotate(180deg);
    transform-origin: center center;
    border-radius: 0 8px 8px 0;
    font-size: 9px;
    justify-content: center;
}

.summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 60px;
    border-bottom: 1px solid colorMystic;
}

.summaryTopRow {
    display: flex;
    flex-direction: column;
}

.shortInfo {
    padding: 12px;
    flex: 1;
}

.shortInfo>:first-child {
    margin-bottom: 12px;
}

.selectionCheckboxProxy {
    border: 1px solid colorShuttleGrey;
    width: 20px;
    height: 20px;
}

.typeBadge {
    font-size: 9px;
}

.detailsOpened {
    max-height: 1000px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.hotelName {
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 500;
}

.hotelLocation {
    line-height: 16px;
    margin-top: 4px;
}

.hotelLocation>:first-child {
    margin-right: 6px;
    width: 16px;
    height: 16px;
}

.guestsArea {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.guestsArea>.leadGuest {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 8px;
}

.guestsArea>:last-child {
    justify-content: space-between;
}

.guestsArea * {
    line-height: 16px;
    font-size: 12px;
}

.leadGuest>:first-child {
    margin-right: 4px;
}

.text {
    color: colorViolet;
}

@media (min-width: smBreak) {
    .shortInfo.collapsed {
        padding-bottom: 12px;
    }

    .shortInfo {
        padding: 16px;
    }
}

@media (min-width: mdBreak) {
    .summary {
        grid-template-columns: 13fr 7fr;
    }

    .summary.collapsed {
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 28px;
    }

    .summaryTopRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .guestsArea {
        grid-template-columns: 3fr 1fr;
        column-gap: 8px;
        align-items: flex-end;
        padding: 32px 0 16px;
    }

    .guestsArea.collapsed {
        grid-template-columns: 1fr 1fr;
        padding-left: 16px;
        column-gap: 12px;
    }

    .guestsArea.collapsed .leadGuest {
        flex-direction: column;
        grid-column: 1;
    }

    .guestsArea.collapsed>:first-child {
        margin-bottom: 0;
    }

    .guestsArea>:last-child {
        justify-content: flex-start;
        padding-right: 0;
    }

    .guests:not(.collapsed) {
        flex-grow: 1;
    }
}
