@value colorShuttleGrey, colorViolet, colorAmaranth from '../../../common/colors/colors.css';
@value unit from '../../../common/metrics.css';

@value shortInputWidth: 104px;

.row {
    gap: calc(unit * 2);
}

.row:first-child {
    margin-bottom: unit;
}

.stripeInput,
.input {
    height: 40px;
    border-radius: 4px;
    padding: 16px 12px;
}

.stripeInput > div {
    width: 100%;
}

.wideInput {
    flex-grow: 1;
}

.shortInput {
    width: shortInputWidth;
}

.wideInput, .shortInput {
    margin-bottom: 0;
}

.label {
    font-size: 12px;
    color: colorViolet;
    text-transform: none;
    margin-bottom: 4px;
    line-height: 16px;
    font-weight: 500;
    font-family: "TT Norms", sans-serif;
    letter-spacing: unset;
}

.termsError span {
    color: colorAmaranth;
}

.noWrap {
    max-width: unset;
}
