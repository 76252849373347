@value baseFontSize from "../globals.css";
@value smallFontSize from "../globals.css";

@value colorBlueRibbon from "../colors/colors.css";

.icon {
  fill: colorBlueRibbon;
  width: 1rem;
  height: 1rem;
  display: inline-flex;
}

/** Sizes */
.icon-xs {
  width: 0.85rem;
  height: 0.85rem;
}

.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-2x {
  width: 2rem;
  height: 2rem;
}

.icon-3x {
  width: 3rem;
  height: 3rem;
}

.icon-4x {
  width: 4rem;
  height: 4rem;
}

.icon-5x {
  width: 5rem;
  height: 5rem;
}

.icon-6x {
  width: 6rem;
  height: 6rem;
}
