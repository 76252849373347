.invitePage {
    height: 100vh;
    background-image: url('../../../assets/img/_pPHgeHz1uk.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 400px;
    max-width: calc(100dvw - 24px);
    padding: 48px 32px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(41, 43, 50, 0.25);
}

.content>*:not(:last-child) {
    margin-bottom: 36px;
}

.logo {
    margin-bottom: 32px;
}

.expirationText {
    margin-bottom: 32px;
}

.submitButton {
    margin-top: 24px;
}

@media (min-width: 400px) {
    .content {
        max-width: 400px;
    }
}
