.backofficePaymentBadge .content {
    align-items: flex-end;
}

.backofficePaymentBadge .content>:first-child {
    font-size: 10px;
    margin-bottom: 2px;
    line-height: 12px;
    font-weight: 500;
}
