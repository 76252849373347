@value (
    colorRemy,
    colorAmaranth,
    colorPolar,
    colorCaribbeanGreen,
    colorSerenade,
    colorLightningYellow,
    colorLightOrange,
    colorCatskillWhite,
    colorViolet,
    colorAqua,
    colorLoblolly
) from "../../../common/colors/colors.css";

.paymentBadge {
    height: 38px;
    width: 196px;
    padding: 4px 16px 4px 4px;
    border-radius: 8px;
    align-self: flex-end;
}

.paymentBadge path {
    fill: white;
}

.pictureContainer {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
}

.text {
    color: colorViolet;
    font-size: 12px;
}

.orange {
    background-color: colorLightOrange;
}

.orange .title {
    color: colorLightningYellow;
}

.orange .pictureContainer {
    background-color: colorLightningYellow;
}

.purple {
    background-color: #F1EEFE;
}

.purple .title {
    color: #7555F8;
}

.purple .pictureContainer {
    background-color: #7555F8;
}

.red {
    background-color: colorRemy;
}

.red .title {
    color: colorAmaranth;
}

.red .pictureContainer {
    background-color: colorAmaranth;
}

.green {
    background: colorPolar;
}

.green .title {
    color: colorCaribbeanGreen;
}

.green .pictureContainer {
    background-color: colorCaribbeanGreen;
}

.yellow {
    background: colorSerenade;
}

.yellow .title {
    color: colorLightningYellow;
}

.yellow .pictureContainer {
    background-color: colorLightningYellow;
}

.grey {
    background: colorCatskillWhite;
}

.grey .title {
    color: colorViolet;
}

.grey .pictureContainer {
    background-color: colorLoblolly;
}

.lightBlue {
    background: colorAqua;
}

.lightBlue .title {
    color: #0FA6ED;
}

.lightBlue .pictureContainer {
    background-color: #0FA6ED;
}
