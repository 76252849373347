@value unit from "../../common/metrics.css";
@value transitionSpeed from "../../common/metrics.css";
@value regular from "../../common/metrics.css";
@value medium from "../../common/metrics.css";

@value colorBlueRibbon from "../../common/colors/colors.css";
@value colorPrimaryGrey from "../../common/colors/colors.css";
@value colorWhite from "../../common/colors/colors.css";
@value colorSecondary from "../../common/colors/colors.css";

@value colorViolet from "../../common/colors/colors.css";
@value colorAmaranth from "../../common/colors/colors.css";
@value colorCaribbeanGreen from "../../common/colors/colors.css";
@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";

.textareaInput {
    position: relative;
    display: inline-flex;
    align-items: center;
    border: 1px solid colorMystic;
    border-radius: calc(1.5 * unit);
    padding: unit calc(2 * unit);
    box-sizing: border-box;
    background-color: white;
    color: colorShuttleGrey;
    font-weight: medium;
    height: auto;
    transition: border-color transitionSpeed, color transitionSpeed;
}
.textareaInput textarea {
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    padding: 0;
    background-color: transparent;
    color: colorViolet;
    width: 100%;
    resize: none;
}
.textareaInput textarea:focus,
.textareaInput textarea:active {
    border: none;
    outline: none;
}

.textareaInput.error {
    border-color: colorAmaranth;
}

.textareaInput textarea::placeholder {
    color: colorShuttleGrey;
    font-weight: regular;
}

.label {
    font-size: 11px;
    line-height: 12px;
    color: #8A9AA7;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: 700;
}

.hasValue,
.textareaInput:hover:not(.disabled):not(.readOnly),
.textareaInput:focus-within:not(.readOnly) {
    color: colorBlueRibbon;
}

.textareaInput:hover:not(.disabled):not(.readOnly):not(.error),
.textareaInput:focus-within:not(.readOnly):not(.error) {
    border-color: colorBlueRibbon;
}

/* Disabled State */

.textareaInput.disabled {
    color: colorShuttleGrey;
    background-color: colorCatskillWhite;
}
.textareaInput.disabled textarea {
    color: colorPrimaryGrey;
}

/* Read Only State */

.textareaInput.readOnly {
    background-color: colorCatskillWhite;
}

.errorText {
    color: colorAmaranth;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
}
