@value borderRadius, borderWidth, unit from "../../../../common/metrics.css";
@value colorMystic, colorCaribbeanGreen, colorAmaranth, colorShuttleGrey from "../../../../common/colors/colors.css";

@value pad: calc(2 * unit);
@value headerMargin: 12px;
@value fontSizeHeader: 16px;

.cancellationPolicy {
    border: borderWidth solid colorMystic;
    border-radius: borderRadius;
    overflow: hidden;
    background: white;
    padding: pad;
    font-weight: 500;
}

.header {
    margin-bottom: headerMargin;
    font-size: fontSizeHeader;
}

.divider {
    height: 1px;
    background-color: colorMystic;
    margin: 0 calc(-2 * unit) pad;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: calc(0.5 * unit);
}

.dueBy {
    color: colorCaribbeanGreen;
    margin-right: 4px;
}

.cancellation {
    color: colorAmaranth;
    margin-right: 4px;
}

.hint {
    color: colorShuttleGrey;
    font-weight: 300;
}

.cancellationCTA {
    text-decoration: underline;
    cursor: pointer;
}
