@value colorBlackSqueeze from "../../../../common/colors/colors.css";
@value colorPrimary from "../../../../common/colors/colors.css";
@value unit from "../../../../common/metrics.css";
@value lightShadow from "../../../../common/metrics.css";

.root {
    width: unset;
    height: unset;
    padding: 24px 32px 16px;
}

.content {
    background-color: white;
    border-radius: 8px;
    position: relative;
    max-width: 695px;
}

.title {
    margin: 0 0 40px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.phoneRow {
    display: flex;
    align-items: flex-end;
    margin-bottom: calc(4 * unit);
}

.phoneRow>:first-child {
    margin-right: unit;
    width: 145px;
}

.phoneRow>:last-child {
    margin-bottom: 0;
    flex-grow: 1;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.actions>* {
    height: 48px;
    border-radius: calc(1.5 * unit);
}

.actions>:first-child {
    width: 72px;
}

.actions>:last-child {
    margin-left: 16px;
    width: 97px;
}

.countryFlag {
    max-height: 10px;
}
