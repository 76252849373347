@value colorBlueRibbon, colorShuttleGrey from "../../../common/colors/colors.css";
@value smBreak from "../../../common/metrics.css";

.bookingAction {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
}

.bookingAction label {
    color: colorShuttleGrey;
    font-size: 12px;
}

.bookingAction a {
    color: colorBlueRibbon;
    letter-spacing: -0.25px;
    font-size: 14px;
}

.bookingAction button {
    border-radius: 4px;
}

.links {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.detailsButton {
    height: 24px;
    font-size: 12px;
}

.detailsButtonInner {
    height: 100%;
    display: flex;
    align-items: center;
}

@media (min-width: smBreak) {
    .bookingAction {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .links {
        flex-direction: row;
        gap: 16px;
        margin-bottom: 0;
    }
}
