@value unit, mdBreak, borderWidth from "../../common/metrics.css";
@value colorBlack, colorWhite from "../../common/colors/colors.css";

@value pagePadding: calc(2 * unit);

.page {
  min-height: calc(100vh - 2 * pagePadding);

  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
  padding: pagePadding;
}

.header {
  height: 30px;
  padding: pagePadding;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: colorWhite;
  background-color: colorBlack;
}

.goLink {
  text-decoration: underline;
  cursor: pointer;
}

.menuIcon {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
 /* Dropdown Button */
.dropbtn {
  cursor: pointer;
  color: white;
  border: none;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropcontent {
  display: none;
  position: absolute;
  background-color: black;
  width: 100%;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropcontent a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropcontent {display: block;}


@media (min-width: mdBreak) {
  .header {
    height: 40px;
  }
}
