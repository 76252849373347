.page {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.title {
  color: black;
  display: inline-block;
}

.topbar1 {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.topbar2 {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  font-size: 14px;
}

.topbar3 {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
  font-size: 14px;
}

.tableholder {
  font-size: 10px;
}

.filterholder {
  font-size: 14px;
}

.pagelocation {
  margin-left: 5px;
  margin-right: 5px;
}

.buttonpanel {
}

.idlink {
  color: blue
}
