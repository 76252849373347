.inputLookupOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background-color: white;
  white-space: nowrap;
  line-height: 44px;
  cursor: pointer;
  font-weight: 300;
}

.inputLookupOption:hover {
  margin: 0 -8px;
  padding: 0 8px;
  border-radius: 5px;
  width: calc(100% + 16px);
  max-width: calc(100% + 16px);
  background-color: #EAEFFE;
}

.inputLookupOption > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.inputLookupOption > *:last-child {
  margin-left: 8px;
}
