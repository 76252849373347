@value (
    unit,
    borderRadius,
    bold,
    borderWidth,
    mdBreak
) from "../../../../common/metrics.css";
@value colorMystic, colorViolet, colorShuttleGrey from "../../../../common/colors/colors.css";

@value pagePadding: calc(2 * unit);

.bookingForm {
    border: borderWidth solid colorMystic;
    border-radius: borderRadius;
    padding: pagePadding;
    background-color: white;
}

.bookingHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: #E1E8ED 1px solid;
}

.bookingPageTitle {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.75px;
    font-feature-settings: 'liga' off;
    margin: 40px 0 24px;
}

.bookingHeader .bookingPageTitle {
    margin: 0;
}

.roomButton {
    margin: 0 calc(2 * unit);
    cursor: pointer;
}

.selectedRoom {
    text-decoration: underline;
    cursor: auto;
}

.row {
    margin-top: calc(3 * unit);
}


.field {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.field:nth-child(2) {
    margin: 0 pagePadding;
}

.bookingForm .inputTitle {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.25px;
    font-feature-settings: 'liga' off;
    color: colorViolet;
    margin-bottom: 4px;
    text-transform: none;
}

.field label {
   font-weight: bold;
}

.fieldInput {
    width: 100%;
}

.commentInput textarea {
    resize: none;
    height: 148px;
    border: none;
    width: 100%;
}

.specialRequests {
    display: flex;
    flex-direction: column;
}

.specialRequestColumn {
    width: 277px;
}

.checkbox {
    margin: calc(2 * unit) 0;
}

.roomDetails.hidden {
    display: none;
}

@media (min-width: mdBreak) {
    .specialRequests {
        flex-direction: row;
        justify-content: space-between;
    }
}
