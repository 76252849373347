@value colorPictonBlue from "../../../common/colors/colors.css";
@value smBreak from "../../../common/metrics.css";

.itineraryAction {
    padding: 6px 8px;
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
}

.itineraryAction>:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
}

.itineraryAction>:first-child button {
    width: 100%;
}

.itineraryAction button,
.itineraryAction a {
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
}

.downloadLink {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.downloadLink:hover {
    text-decoration: none;
}

.expandButton {
    width: 114px;
    padding: 4px 10px 4px 6px;
}

.expandButtonInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.expandButton svg {
    width: 6px;
    height: 5px;
    transition: all 200ms;
}

.downloadButton {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.expandButton svg path {
    fill: colorPictonBlue;
}

.expandButton.toggled svg {
    transform: rotate(180deg);
    transform-origin: center;
}

@media (min-width: smBreak) {
    .itineraryAction {
        padding: 12px 16px;
        flex-direction: row;
        justify-content: space-between;
    }

    .itineraryAction>:first-child {
        display: flex;
    }

    .itineraryAction>:first-child button {
        width: auto;
    }
}