@value colorShuttleGrey from '../../common/colors/colors.css';
@value colorBlueRibbon from '../../common/colors/colors.css';

.root {
    flex-grow: 1;
}

.content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    color: colorShuttleGrey;
    text-transform: capitalize;
    background-color: transparent;
    border-radius: 12px;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}
.content:not(.selected):hover {
    border: 1px solid colorBlueRibbon;
    cursor: pointer;
}

.selected {
    background-color: colorBlueRibbon;
    color: white;
}
