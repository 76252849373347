@value borderRadius, borderWidth, unit from "../../common/metrics.css";
@value zIndexTop from "../../common/globals.css";

.tooltipWrapper {
    position: relative;
}

.tooltipWrapper:hover > .tooltip {
    visibility: visible;
}

.tooltip {
    z-index: zIndexTop;
    background-color: rgba(62, 62, 62, 0.8);
    border-radius: borderRadius;
    color: white;
    font-size: 12px;
    min-height: 32px;
    max-height: 40px;
    max-width: 200px;
    width: max-content;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    visibility: hidden;
    position: fixed;
    line-height: 12px;
}

.top::after {
    content: " ";
    left: 50%;
    bottom: -4px;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 3px solid transparent;
    margin-left: calc(2px * -1);
    border-top-color: rgba(62, 62, 62, 0.8);
}

.bottom::after {
    content: " ";
    left: 50%;
    top: -4px;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 2px solid transparent;
    margin-left: calc(2px * -1);
    border-bottom-color: rgba(62, 62, 62, 0.8);
}
