@value unit, smBreak from "../../../common/metrics.css";
@value colorBlueRibbon, colorViolet, colorPrimary from "../../../common/colors/colors.css";

@value profileImageSize: 24px;

@value iconMargin: 8px;
@value iconSize: 17px;

.dropdown {
  flex-grow: 1;
}

.profileMenu {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media (min-width: smBreak) {
  .dropdown {
    flex-grow: 0;
  }
}

.profileImage {
  width: profileImageSize;
  height: profileImageSize;
  border-radius: 50%;
  border: 2px solid colorPrimary;
  font-weight: 700;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colorPrimary;
  text-transform: uppercase;
}

.userName {
  margin-right: 5px;
}
.userName b {
  font-weight: bold;
}

.dropdownIcon {
  margin-left: 6px;
}
.dropdownIcon svg {
  width: 7px;
}

.navLinkList {
  display: flex;
  flex-direction: column;
}

.navLink {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navLink:last-child {
  margin-bottom: 0;
}
.navLink:hover {
  text-decoration: none;
  color: colorBlueRibbon;
}

.iconComponent path {
  fill: currentColor;
}

.navLink svg,
.iconComponent {
  display: inline-block;
  margin-right: iconMargin;
  width: iconSize;
  height: iconSize;
}
