@value unit from "../../../../common/metrics.css";

.contactDetails {
    display: flex;
    flex-direction: column;
    max-width: 632px;
}
.managerContactNumber {
    display: flex;
    margin-bottom: 24px;
    align-items: flex-end;
}

.countryCodeDropdown {
    max-width: 145px;
    margin-right: unit;
}

.countryCodeDropdownList {
    height: 200px;
}

.countryAndTimezone {
    margin-bottom: 24px;
    grid-template-columns: repeat(2, 1fr);
}

.multipleInputs {
    display: grid;
    column-gap: 16px;
}

.multipleInputs>* {
    width: 100%;
}

.addressDetails {
    grid-template-columns: repeat(3, 1fr);
}

.stateDropdown {
    margin-bottom: 24px;
}
