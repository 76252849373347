@value colorViolet, colorCatskillWhite, colorBlueRibbon, colorShuttleGrey from "../../../../common/colors/colors.css";
@value smBreak from "../../../../common/metrics.css";

.priceSummary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  justify-items: center;
  padding-top: 16px;
}

.priceSummary .priceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 137px;
  height: 84px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(41, 43, 50, 0.08);
  border-radius: 8px;
  margin-right: 16px;
}
.priceSummary .priceItem:last-child {
  margin-right: 0;
}

.priceItem .priceItemTitle {
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorShuttleGrey;
  margin-bottom: 8px;
  width: 100%;
}

.priceItem .priceItemValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.priceItem .priceItemValue .priceValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: colorViolet;
  margin-right: 4px;
}

.priceItem .priceItemValue .currency {
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorShuttleGrey;
}

@media (min-width: smBreak) {
  .priceSummary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 0;
  }
}
