@value (
  colorBlackSqueeze,
  colorViolet,
  colorShuttleGrey,
  colorWhite,
  colorCatskillWhite,
  colorBackgroundGrey,
  colorPrimaryYellow,
  colorPrimary,
  colorSky,
  colorLightGrey
) from "../../common/colors/colors.css";

@value unit, mdBreak, lgBreak from "../../common/metrics.css";

@value topRowHeight: 100px;
@value boxWidth: 848px;

.searchBarContainer {
  background-color: colorBlackSqueeze;
}

.pageContainer {
  background-color: colorBackgroundGrey;
}

.hotelDetails {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 3);
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hotelTitleSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.starIcon {
  fill: colorPrimaryYellow;
}

.hotelName {
  font-size: 24px;
}

.hotelAddress {
  font-size: 16px;
  color: colorShuttleGrey;
}

.hotelPriceSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.hotelRooms {
  width: 100%;
}

.middleImageRow {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 2);
}

.rightImageColumn {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 2);
}

.mapView {
  width: 100%;
  height: 156px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: url('../../../assets/img/map-view-details-bg.png') no-repeat;
  background-size: cover;
}

.mapView .mapViewInner {
  background: #FFFFFF;
  border-radius: 4px;
  width: 105px;
  height: 24px;

  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: colorPrimary;
}

.lowestAverageNightlyRate {
  color: colorSky;
  font-size: 12px;
}

.nearbyLandmarks {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 2);
  border: 1px solid colorBlackSqueeze;
  padding: calc(3 * unit) calc(3 * unit) 0;
  border-radius: 8px;
  background-color: colorWhite;
  color: colorShuttleGrey;
}

.nearbyLandmarks .poiDistance {
  font-weight: 700;
  color: colorViolet;
  margin-right: calc(3 * unit);
}

.nearbyLandmarks .poiName {
  color: colorShuttleGrey;
}

.nearbyLandmarks .nearbyLandmarkRow + .nearbyLandmarkRow {
  padding-top: calc(2 * unit);
  border-top: 1px solid colorCatskillWhite;
}

.contentSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.contentTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
}

.box {
  border: 1px solid colorBlackSqueeze;
  border-radius: 8px;
  padding: 16px;
  height: fit-content;
  display: flex;
  background-color: colorWhite;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 32px;
}

.blockHeading {
  color: colorViolet;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.contentGroup {
  display: flex;
  flex-direction: column;
}

.contentGroup + .contentGroup {
  margin-top: 32px;
}

.blockBodyText {
  color: colorShuttleGrey;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: pre-wrap;
}

.aboutThisAreaTopRowTextSection {
  flex-direction: column;
  flex: 1;
  margin-right: 40px;
}

.aboutThisArea {
  display: flex;
  flex-direction: column;
}

.aboutThisAreaTopRow {
  display: flex;
  flex-direction: row;
}

.aboutThisAreaMap {
  height: 340px;
  flex: 1;
}

.aboutThisAreaColumns {
  display: flex;
  flex-direction: column;
}

.aboutThisPropertyBox {
  flex-direction: column;
}

.searchBarContainer {
  background-color: colorBlackSqueeze;
}

.printPdfButton {
  height: 56px;
  padding: 16px 34px;
}

.printPdfButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.printPdfButtonInner>:first-child {
  margin-right: 11px;
}

.toColumns {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
}

.toColumns > div {
  max-width: 50%;
  flex: 1 1 50%;
  padding: 0 32px;
  margin: 0 0 32px !important;
}

.paymentSection {
  display: flex;
}

.paymentIcon {
  width: 55px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: colorLightGrey 1px solid;
  border-radius: 5px;
  margin-right: 10px;
}

.paymentIcon:last-child {
  margin-right: 0;
}

.paymentIcon img {
  width: 40px;
  height: 20px;
}

@media (min-width: mdBreak) {
  .topRow {
    height: topRowHeight;
  }

  .rightImageColumn {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 156px auto;
    column-gap: calc(unit * 3);
  }

  .nearbyLandmarks {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .contentSection {
    flex-direction: row;
    margin-top: 42px;
  }
  
  .contentTitle {
    margin-bottom: 0;
  }

  .box {
    padding: 32px;
    width: boxWidth;
  }

  .aboutThisAreaColumns {
    flex-direction: row;
  }
}

@media (min-width: lgBreak) {
  .middleImageRow {
    flex-direction: row;
  }

  .rightImageColumn {
    display: flex;
    flex-direction: column;
  }
}
