@value unit from "../../common/metrics.css";
@value transitionSpeed from "../../common/metrics.css";
@value regular from "../../common/metrics.css";
@value medium from "../../common/metrics.css";

@value colorBlueRibbon from "../../common/colors/colors.css";
@value colorPrimaryGrey from "../../common/colors/colors.css";
@value colorPrimary from "../../common/colors/colors.css";
@value colorWhite from "../../common/colors/colors.css";
@value colorSecondary from "../../common/colors/colors.css";

@value colorViolet from "../../common/colors/colors.css";
@value colorAmaranth from "../../common/colors/colors.css";
@value colorCaribbeanGreen from "../../common/colors/colors.css";
@value colorCatskillWhite from "../../common/colors/colors.css";
@value colorMystic from "../../common/colors/colors.css";
@value colorShuttleGrey from "../../common/colors/colors.css";


@value inputSizeUnit: 48px;
@value iconSize: 32px;

.inputText {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: inputSizeUnit;
    border: 1px solid colorMystic;
    border-radius: calc(1.5 * unit);
    padding: unit calc(2 * unit);
    box-sizing: border-box;
    background-color: white;
    color: colorShuttleGrey;
    font-weight: medium;
}

.inputText.error {
    border-color: colorAmaranth;
}

/* The labelContainer is used to layout the artificial label and the input */
.labelContainer {
    width: inherit;
    flex-grow: 1;
}

.icon {
    position: relative;
    display: flex;
    max-width: iconSize;
    max-height: iconSize;
}

.icon svg {
    overflow: visible;
    height: 17px;
    width: 17px;
}

.icon path {
    fill: colorShuttleGrey;
}

.icon.clickable {
    cursor: pointer;
}

.icon.clickable:hover path {
    fill: colorViolet;
}

.icon.clickable:active path {
    fill: colorBlueRibbon;
}

.icon:first-child {
    margin-right: unit;
}

.icon:last-child {
    margin-left: unit;
}

/* Input Style */

.inputText input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    color: colorViolet;
    text-overflow: ellipsis;
}

.inputText input {
    color: colorViolet;
}

.inputText input::placeholder,
.inputText textarea::placeholder{
    color: colorShuttleGrey;
    font-weight: regular;
}

/* If there is a label then the input should be shifted down */
.label + input {
    top: 6px;
}

/* Label Styles - for animated floating label derived from the placeholder */

.label {
    position: absolute;
    /* font-size: 1em; */
    font-weight: medium;
    top: 50%;
    transform: translateY(-50%);
    color: colorPrimaryGrey;
    transition: top transitionSpeed ease-in-out, font-size transitionSpeed ease-in-out;
}

/*  If the input has entered text, or if it has focus,
    then shift the label up and reduce size */
.inputText.hasValue .label,
.inputText:focus-within .label {
    top: 13px;
    font-size: 10px;
    line-height: 1em;
}

/* Hover, Focused, Text Entered States */

.inputText
{
    transition: border-color transitionSpeed, color transitionSpeed;
}

.inputText:hover:not(.disabled):not(.readOnly),
.inputText:focus-within:not(.readOnly)
{
    color: colorBlueRibbon;
    border-color: colorPrimary;
}

.inputText:hover:not(.disabled):not(.readOnly):not(.error),
.inputText:focus-within:not(.readOnly):not(.error)
{
    border-color: colorBlueRibbon;
}

/* Disabled State */

.inputText.disabled {
    color: colorShuttleGrey;
    background-color: colorCatskillWhite;
}

.inputText.disabled input {
    color: colorPrimaryGrey;
}

/* Read Only State */

.inputText.readOnly
{
    background-color: colorCatskillWhite;
}

.labelContainer input {
    width: 100%;
}

.errorText {
    color: colorAmaranth;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1.2;
}

.short {
    width: 308px;
}

.medium {
    width: 416px;
}

.long {
    width: 632px;
}

.fullwidth {
    width: 100%;
}
