@value smBreak from "../../../common/metrics.css";

.filtersHeader {
  display: flex;
  justify-content: space-between;
}

.filtersBody {
  margin: 20px 0 0;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.filterItem {
  height: 48px;
  width: 100%;
}

.paidOptionsWrapper {
  display: flex;
  flex-direction: row;
  height: 24px;
}

.paidOption {
  margin-right: 10px;
  height: 24px;
}

.paidOption:focus {
  box-shadow: none;
  border: none;
  outline: none;
  height: 24px;
  margin-right: 10px !important;
}

.filterItem .checkboxProxy {
  height: 20px;
  width: 20px;
}
.filterItem .checkboxText {
  max-width: calc(100% - 20px - 8px);
}

@media (min-width: smBreak) {
  .filtersBody {
    flex-direction: row;
    gap: 10px;
  }

  .destinationNameInput {
    width: 300px;
  }

  .filterItem {
    width: auto;
  } 
}
