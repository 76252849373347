@value smBreak from "../../../../../common/metrics.css";

.root {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.root.collapsed {
    margin-top: 8px;
}

.paymentDue {
    align-items: flex-end;
}

.title {
    margin-bottom: 2px;
}

.text {
    font-weight: 300;
    line-height: 16px;
}

@media (min-width: smBreak) {
    .root {
        align-self: flex-end;
        margin-bottom: 0;
    }
}
