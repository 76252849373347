@value colorPrimaryGrey from "../../../common/colors/colors.css";

.dashboardQuickSearch {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.title>h3{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-weight: 500;
    margin-right: 8px;
}

.title>div {
    height: 20px;
}

.title path {
    fill: colorPrimaryGrey;
}

.title ol {
    list-style: inside;
}

.title span {
    display: block;
    margin-bottom: 4px;
}

.dashboardQuickSearch li {
    line-height: 20px;
}

.hint {
    max-height: unset;
    max-width: unset;
}
