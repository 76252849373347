@value unit, mdBreak from "../../../common/metrics.css";
@value colorBlackSqueeze from "../../../common/colors/colors.css";
@value colorBlueRibbon from "../../../common/colors/colors.css";
@value colorShuttleGrey from "../../../common/colors/colors.css";

/* Styles used for temporary components delete these once the temporary components are no longer used */

.hotelRoomInput {
  display: flex;
}

.location {
  cursor: pointer;
}

.searchResults {
  flex: 1 0 auto;
  margin: 1.5rem 0;
}

.pageSectionContainer {
  display: flex;
  flex: 1 0 auto;
}

.pageSectionContainer > *:nth-child(1) {
  width: calc(100% / 12 * 3);
  margin-right: calc(4 * unit);
  max-width: 308px;
}

.pageSectionContainer > *:nth-child(2) {
  width: calc(100% / 12 * 9);
}

.hotelResult {
  margin-bottom: 24px;
}

.searchBarContainer {
  background-color: colorBlackSqueeze;
}

.content {
  flex: 1;
}

.commission {
  width: 80px;
}

.inputTextReadOnly {
  background-color: white !important;
}

.inputTextReadOnly:hover {
  border-color: colorBlueRibbon !important;
}

.noData {
  font-size: 14px;
  text-align: center;
  margin: 3rem auto;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.25px;
  color: colorShuttleGrey;
}

@media (min-width: mdBreak) {
  .hotelResultRoot {
    grid-row: 2;
    grid-column: 2; 
  }
}
