@value unit from "../../common/metrics.css";
@value baseIconSize from "../../common/metrics.css";
@value transitionSpeed from "../../common/metrics.css";

@value inputWidth: 24px;

.inputStepper {
    width: fit-content;
    position: relative;
    display: inline-block;
}

.inputText {
    margin-left: unit;
    margin-right: unit;
}

.button {
    width: baseIconSize;
    height: baseIconSize;
}

.inputStepper .input {
    width: inputWidth;
    text-align: center;
}