@value colorShuttleGrey from '../../common/colors/colors.css';
@value unit from "../../common/metrics.css";

.inputBox {
    margin-bottom: calc(3 * unit);
}

.label {
    font-size: 11px;
    line-height: 12px;
    color: colorShuttleGrey;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: unit;
    font-weight: 700;
}
