/* based on https://github.com/reach/reach-ui/blob/43f450db7bcb25a743121fe31355f2294065a049/packages/visually-hidden/src/reach-visually-hidden.tsx */
.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  word-wrap: normal;
}
