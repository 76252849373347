@value unit, borderRadius, borderWidth from "../../../../common/metrics.css";

@value (
    colorMystic,
    colorShuttleGrey,
    colorBackgroundGrey
) from "../../../../common/colors/colors.css";

@value pad: calc(2 * unit);
@value headerMargin: 12px;
@value fontSizeHeader: 16px;
@value fontSizePrice: 18px;
@value lineHeightPrice: 56px;

.priceSummary {
    border: borderWidth solid colorMystic;
    border-radius: borderRadius;
    overflow: hidden;
    background: white;
    padding: pad 0;
}

.priceSummary > * {
    padding: 0 pad;
}

.header {
    margin-bottom: headerMargin;
    font-size: fontSizeHeader;
    font-weight: 500;
}

.divider {
    height: 1px;
    background-color: colorMystic;
    margin: 0 calc(-2 * unit) pad;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: calc(0.5 * unit);
    font-weight: 500;
}

.price {
    line-height: lineHeightPrice;
    font-size: fontSizePrice;
    background-color: colorBackgroundGrey;
}

.row:first-child {
    margin-top: pad;
}

.caveat {
    color: colorShuttleGrey;
}
