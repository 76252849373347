@value colorBlueRibbon from "../../common/colors/colors.css";

.dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;

  background: white;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
}

.dropdownWrapper {
  position: relative;
  border-bottom: 4px solid transparent;
}